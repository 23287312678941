.aos-title-block {
  width: '100%';
  font-weight: bold;
  height: 45px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding-left: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.aos-content-block {
  border: ' 1px solid #ddd' !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
