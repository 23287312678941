.login {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .content {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 24px;
    .title {
      margin: 50px;
      display: flex;
      justify-content: center;
    }
    .form-login {
      margin: 20px 0 0 0;
      width: 100%;
      max-width: 500px;
      justify-self: center;
      align-self: center;
    }
    .btn-submit {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .btn-login {
      margin-top: 20px;
      width: 200px;
    }
  }
  .footer {
    text-align: center;
    margin-top: 20px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-content: center;
    // align-items: center;
    // bottom: 30px;
    // padding: 24px;
    // left: 200px;
  }
}

@media screen and (max-width: 768px) {
  .login {
    .content {
      padding: 24px;
      max-width: 100%;
      .title {
        margin: 24px;
      }
      .form-login {
        margin: 24px 0 0 0;
        width: 100%;
      }
      .btn-login {
        margin-top: 24px;
        width: 100% !important;
        max-width: 350px;
      }
    }
    .footer {
      display: none !important;
    }
  }
}
