
.center_register-form {
    min-height: 900px;
    .member_register-tabs {
        .tab-pane {
            display: none;
            &.current{
                display: inherit;
            }
        }
        .management-content { 
            .form-vender, .form-type, .form-selectUse, .form-represent {
                width: 100%;
            }
            .group-form {
                display: flex;
                margin: 15px 35px;
                gap: 50px;
                .input-wrapper {
                    margin-top: 5px;
                    .custom-select {
                        width: 100%;
                        padding: 10px 15px;
                        border-radius: 4px;
                        border: 1px solid $color_3;
                        color: $color_3;
                    }
                }
                .result {
                    color: $color_7;
                    font-size: 13px;
                }
            }
            .table-wrapper {
                table {
                    th:nth-child(2) {
                        text-align: center;
                    }
                    td {
                        a {
                          color: $color_7;
                          text-decoration: none;
                          cursor: pointer;
                          &:hover {
                            color: $color_1;
                          }
                        }
                        &:nth-child(2) {
                            text-align: center;
                        }
                    }
                    .label-check {
                        padding-left: 15px;
                    }
                }
            }
            .form-input {
                margin: 0;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid $color_3;
                color: $color_3;
            }
            .form-zone {
                margin-right: 20px;
            }
            .group-flex {
                display: flex;
                justify-content: center;
            }
            .form-button {
                justify-content: space-between;
                align-items: center;
                .group-button {
                    margin-top: 0;
                }
            }
            .search-input .btn-primary {
                border-radius: 50px;
                margin: 0 5px;
                // border: 1px solid #00BFDF;
                transition: all 0.1s ease-in;
                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 7px;
                }
            }
        } 
    } 
} 

.user_register-title {
    display: flex;
    margin-left: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 13px;
    color: #6D6E71;
    svg {
        width: 20px;
        margin-right: 6px;
    }
}
.member_register-form {
    min-height: 900px;
    .member_register-tabs {
        .registration-content {
            .form-heading {
                position: relative;
                z-index: 15;
                color: $color_7;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 0.5em;
                    border-top: 1px solid #BCBEC0;
                    z-index: -1;
                }
            }
        }
        .modal-selectBank{
            .alert {
                width: 100%;
                .radio_check-wrapper {
                    flex-direction: column;
                    align-items: flex-start;
                    .label-check {
                        margin: 5px;
                        &:nth-child(2) {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .vender {
            .modal-selectType,.modal-countrym{
                .alert {
                    width: 100%;
                    .radio_check-wrapper {
                        flex-direction: column;
                        align-items: flex-start;
                        .label-check {
                            margin: 5px;
                            &:nth-child(2) {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
        .tab-pane {
            display: none;
            &.current{
                display: inherit;
            }
        }
        .group-form {
            .custom-select {
                min-width: 65px;
                padding: 15px 10px;
            }
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 32px;
            height: 17px;
            margin: 10px;
            input { 
                opacity: 0;
                width: 0;
                height: 0;
              }   
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color_2;
            -webkit-transition: .4s;
            transition: .4s;
            &:before {
                position: absolute;
                content: "";
                height: 15px;
                width: 15px;
                left: -1px;
                bottom: -1px;
                background-color: $color_2;
                -webkit-transition: .4s;
                transition: .4s;
            }
            
        }
        input:checked + .slider {
            background-color: $color_1;
        }
          
        input:focus + .slider {
            box-shadow: 0 0 1px $color_1;
        }
          
        input:checked + .slider:before {
            -webkit-transform: translateX(15px);
            -ms-transform: translateX(15px);
            transform: translateX(15px);
        }
        .slider.round {
            border-radius: 34px;
            border: 1px solid $color_3;
        }
        .slider.round:before {
            border-radius: 50%;
            border: 1px solid $color_3;
        }
    }
    .deal-date, .group-form {
        a {
            &:hover {
                svg {
                    path {
                        fill: $color_1;;
                    }
                }
            }
        }
        svg {
            width: 20px;
        }
    }
}