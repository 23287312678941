@import 'variable';

@font-face {
    font-family: 'Cafe24Behappy';
    src: url('./fonts/htmleditor/Cafe24Behappy.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Classictype';
    src: url('./fonts/htmleditor/Cafe24Classictype.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Dangdanghae';
    src: url('./fonts/htmleditor/Cafe24Dangdanghae.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Danjunghae';
    src: url('./fonts/htmleditor/Cafe24Danjunghae.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Decobox';
    src: url('./fonts/htmleditor/Cafe24Decobox.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Decoline';
    src: url('./fonts/htmleditor/Cafe24Decoline.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Decomilk';
    src: url('./fonts/htmleditor/Cafe24Decomilk.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Decoschool';
    src: url('./fonts/htmleditor/Cafe24Decoschool.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Decoshadow';
    src: url('./fonts/htmleditor/Cafe24Decoshadow.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Decozoo';
    src: url('./fonts/htmleditor/Cafe24Decozoo.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Dongdong';
    src: url('./fonts/htmleditor/Cafe24Dongdong.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Lovingu';
    src: url('./fonts/htmleditor/Cafe24Lovingu.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Ohsquare';
    src: url('./fonts/htmleditor/Cafe24Ohsquare.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Ohsquareair';
    src: url('./fonts/htmleditor/Cafe24Ohsquareair.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Oneprettynight';
    src: url('./fonts/htmleditor/Cafe24Oneprettynight.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Shiningstar';
    src: url('./fonts/htmleditor/Cafe24Shiningstar.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Simplehae';
    src: url('./fonts/htmleditor/Cafe24Simplehae.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Ssukssuk';
    src: url('./fonts/htmleditor/Cafe24Ssukssuk.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Ssurround';
    src: url('./fonts/htmleditor/Cafe24Ssurround.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24SsurroundAir';
    src: url('./fonts/htmleditor/Cafe24SsurroundAir.ttf') format('truetype');
}

@font-face {
    font-family: 'Cafe24Syongsyong';
    src: url('./fonts/htmleditor/Cafe24Syongsyong.ttf') format('truetype');
}


@font-face {
    font-family: 'NanumBarunGothic';
    src: url('./fonts/htmleditor/NanumBarunGothic.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumBarunGothicYetHangul';
    src: url('./fonts/htmleditor/NanumBarunGothicYetHangul.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumBrush';
    src: url('./fonts/htmleditor/NanumBrush.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumGothic';
    src: url('./fonts/htmleditor/NanumGothic.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumGothicEco';
    src: url('./fonts/htmleditor/NanumGothicEco.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumMyeongjo';
    src: url('./fonts/htmleditor/NanumMyeongjo.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumMyeongjoEco';
    src: url('./fonts/htmleditor/NanumMyeongjoEco.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumMyeongjoYetHangul';
    src: url('./fonts/htmleditor/NanumMyeongjoYetHangul.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumPen';
    src: url('./fonts/htmleditor/NanumPen.ttf') format('truetype');
}