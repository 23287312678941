#dropzone-external {
  width: 200px;
  height: 200px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}
#dropzone-external > * {
  pointer-events: none;
}
#dropzone-external.dropzone-active {
  border-style: solid;
}
.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}
#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}
#dropzone-text > span {
  font-weight: 400;
  opacity: 0.7;
}
#upload-progress {
  display: flex;
  margin-top: 10px;
}
.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-upload-custom {
  .dx-fileuploader-input-wrapper {
    display: flex;
    justify-content: center;
    .dx-fileuploader-button {
      display: block;
    }
  }
  .dx-fileuploader-button {
    display: none;
  }
}
// .icon-upload-custom {
// }
.btn-cancel-custom {
  position: absolute;
  top: 220px;
  left: 50%;
  .dx-button-content {
    height: 35px;
    padding: 7px 18px 8px;
  }
}

.uploadImg {
  > div {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    #dropzone-external {
      flex: 0 0 100%;
    }
    .btn-cancel-custom {
      order: 3;
      position: relative;
      top: unset;
      left: unset;
      height: 32px;
    }
    #file-uploader {
      .dx-fileuploader-wrapper {
        height: auto;
        width: auto;
        .dx-fileuploader-container {
          table-layout: unset;
          .dx-fileuploader-input-container {
            display: none !important;
            > div {
              display: none !important;
            }
          }
        }
      }
      .dx-fileuploader-files-container {
        display: none;
      }
    }
  }
}
