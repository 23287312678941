.center_register-form {
  .center_tab-pane {
    display: none;
    .group-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 20px;
    }
    .pagination {
      display: flex;
      list-style: none;
      justify-content: flex-end;
      border-radius: 3px;
      .page-item {
        &.active {
          .page-link {
            z-index: 2;
            background: $color_2;
            border-color: $color_1;
          }
        }
        .page-link {
          position: relative;
          display: flex;
          padding: 5px 8px;
          margin-right: 10px;
          line-height: 1.25;
          color: $color_1;
          border: 1px solid transparent;
          border-radius: 50px;
          text-decoration: none;
          font-size: 13px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .prev {
        .page-link {
          display: flex;
          align-items: center;
          border: 1px solid $color_1;
          svg {
            width: 13px;
            margin-right: 10px;
          }
        }
        &.disabled {
          background: #ffffff;
          .page-link {
            background: $color_3;
            color: $color_2 !important;
            border-color: $color_3 !important;
            svg {
              path {
                fill: $color_2;
              }
            }
          }
        }
      }
      .next {
        .page-link {
          border: 1px solid $color_1;
          svg {
            width: 13px;
            margin-right: 10px;
          }
        }
        &.disabled {
          background: #ffffff;
          .page-link {
            background: $color_3;
            color: $color_2 !important;
            border-color: $color_3 !important;
            svg {
              path {
                fill: $color_2;
              }
            }
          }
        }
      }
    }
    .custom-select {
      min-width: 46px;
      padding: 4px;
      cursor: pointer;
      background: #ffffff url(../images/Asset10@2x.png) right 0.5rem center/10px
        10px no-repeat;
      &:focus {
        outline: none;
      }
    }
    &.active {
      display: block;
    }
    .label {
      font-size: 11px;
      color: $color_7;
    }
    .code_registration {
      display: flex;
      &-content {
        width: 100%;
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 500px;
        border: 1px solid $color_3;
        .group-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px;
        }
        .pagination {
          display: flex;
          list-style: none;
          justify-content: flex-end;
          border-radius: 3px;
          .page-item {
            &.active {
              .page-link {
                z-index: 2;
                background: $color_2;
                border-color: $color_1;
              }
            }
            .page-link {
              position: relative;
              display: flex;
              padding: 5px 8px;
              margin-right: 10px;
              line-height: 1.25;
              color: $color_1;
              border: 1px solid transparent;
              border-radius: 50px;
              text-decoration: none;
              font-size: 13px;
              &:hover {
                opacity: 0.7;
              }
            }
          }
          .prev {
            .page-link {
              display: flex;
              align-items: center;
              border: 1px solid $color_1;
              svg {
                width: 13px;
                margin-right: 10px;
              }
            }
          }
          .next {
            .page-link {
              border: 1px solid $color_1;
              svg {
                width: 13px;
                margin-right: 10px;
              }
            }
          }
        }
        .custom-select {
          min-width: 46px;
          padding: 4px;
          cursor: pointer;
          background: #ffffff url(../images/Asset10@2x.png) right 0.5rem
            center/10px 10px no-repeat;
          &:focus {
            outline: none;
          }
        }
        .modal-addNew {
          overflow: hidden;
        }
        .addNew {
          max-width: 518px;
          margin: 0 auto;
          border: 1px solid #bcbec0;
          background: #ffffff;
          margin-bottom: 210px;
          position: relative;
          top: 15%;
          width: 80%;
          padding: 20px;
          .addNew-heading {
            display: flex;
            justify-content: center;
            font-size: 25px;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 30px;
          }
          .group-button {
            margin-top: 15px;
          }
          .form {
            max-width: 412px;
            margin: 0 auto;
            .form-input {
              padding: 5px;
            }
            .form-code,
            .form-codeName,
            .form-note {
              margin-bottom: 25px;
            }
            .switch {
              position: relative;
              display: inline-block;
              width: 32px;
              height: 17px;
              margin-top: 10px;
              input {
                opacity: 0;
                width: 0;
                height: 0;
              }
            }
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: $color_2;
              -webkit-transition: 0.4s;
              transition: 0.4s;
              &:before {
                position: absolute;
                content: '';
                height: 15px;
                width: 15px;
                left: -1px;
                bottom: -1px;
                background-color: $color_2;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }
            }
            input:checked + .slider {
              background-color: $color_13;
            }

            input:focus + .slider {
              box-shadow: 0 0 1px $color_1;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(15px);
              -ms-transform: translateX(15px);
              transform: translateX(15px);
            }
            .slider.round {
              border-radius: 34px;
              border: 1px solid $color_3;
            }
            .slider.round:before {
              border-radius: 50%;
              border: 1px solid $color_3;
            }
          }
        }
        .group-form {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 20px;
          .result {
            margin-left: 30px;
            color: $color_7;
            font-size: 13px;
          }
          .group-button {
            margin-top: 0;
            .btn-delete,
            .btn-create {
              border: 1px solid $color_3;
              border-radius: 3px;
              margin: 0 10px;
              color: $color_7;
              background: $color_16;
              &:hover {
                opacity: 0.7;
              }
            }
            .btn-delete {
              background: $color_18;
            }
          }
        }
        .input-group {
          margin-top: 40px;
          margin-bottom: 100px;
          margin-left: 15px;
          max-width: 415px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .form-input {
            border-radius: 50px;
            border: 1px solid $color_3;
            margin: 0;
            padding: 7px 10px;
            color: $color_16;
            &::placeholder {
              color: $color_16;
            }
          }
          .btn-primary {
            border-radius: 50px;
            margin: 0 5px;
            border: 1px solid $color_1;
            transition: all 0.1s ease-in;
            margin-right: 40px;
            &:hover {
              border-color: $color_17;
              color: $color_7;
              transition: all 0.1s ease-in;
              .cls-3 {
                fill: $color_7;
                transition: all 0.1s ease-in;
              }
            }
            &:disabled,
            &[disabled] {
              background: $color_3;
              color: $color_2;
              border: 1px solid transparent;
              cursor: not-allowed;
              .cls-3 {
                fill: $color_2;
              }
            }
            svg {
              width: 16px;
              height: 16px;
              margin-right: 7px;
            }
          }
        }
      }
      .code-navbar {
        min-width: 250px;
        border: 1px solid $color_3;
        margin-top: 10px;
        margin-bottom: 10px;
        .menu-list {
          list-style: none;
          padding: 0 30px;
          .menu-item {
            margin-top: 30px;
            > a {
              text-decoration: none;
              font-size: 14px;
              color: $color_7;
              display: flex;
              align-items: center;
              img {
                width: 10px;
                margin-right: 10px;
              }
            }
          }
          .submenu {
            list-style: none;
            padding: 0 25px;
            display: block;
            &.showMenu {
              display: none;
            }
            li {
              margin: 10px 0;
              a {
                text-decoration: none;
                font-size: 14px;
                color: $color_7;
                padding: 2px;
                border: 1px solid transparent;
                &.active {
                  background: orange;
                  border: 1px solid $color_3;
                  color: $color_2;
                }
              }
            }
          }
        }
      }
    }
  }
  .form-main {
    .btn-center {
      justify-content: center;
      margin-bottom: 65px;
    }
    .btn-primary {
      border-radius: 50px;
      margin: 0 0 5px 19px;
      border: 1px solid $color_1;
      transition: all 0.1s ease-in;
      &:hover {
        border-color: $color_17;
        color: $color_7;
        transition: all 0.1s ease-in;
        .cls-3 {
          fill: $color_7;
          transition: all 0.1s ease-in;
        }
      }
      &:disabled,
      &[disabled] {
        background: $color_3;
        color: $color_2;
        border: 1px solid transparent;
        cursor: not-allowed;
        .cls-3 {
          fill: $color_2;
        }
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        transition: all 0.1s ease-in;
      }
    }
    .input_Opera-time {
      min-height: 76px;
      width: 100%;
      resize: none;
    }
  }
}
.admin {
  position: relative;
}
.sidebar_register {
  background: $color_1;
  width: 55px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  .sidebar-brand {
    padding: 6px;
  }
  &.sidebar-Collapse {
    width: 146px;
  }
  &-menu {
    svg {
      width: 20px;
      height: 20px;
      path {
        &.cls-2 {
          fill: $color_1;
        }
      }
    }
    .tab-link {
      padding: 15px;
      // border-top: 1px solid $color_1;
      border-bottom: unset;
      justify-content: center;
      &.active {
        background: $color_6;
        border-right: none;
      }
    }
  }
  .tab-search {
    background: $color_6;
  }
}
.center_register-main {
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  position: absolute;
  header {
    width: 100%;
    position: sticky;
    top: 0;
    background: $color_2;
    z-index: 1029;
    .logo-mb {
      display: none;
    }
  }
  &.main-slide {
    margin-left: 146px;
    width: calc(100% - 146px);
    position: absolute;
    .program-form {
      min-width: 633px;
      transition: all 0.5s ease-in-out;
    }
  }
  .nav-left {
    padding: 15px 0;
    list-style: none;
    display: flex;
    gap: 20;
    .nav-item:nth-child(1) {
      .nav-link {
        margin-right: 4px;
        img {
          width: 20px;
          height: 15px;
        }
      }
    }
    .nav-item:nth-child(2) {
      .nav-link {
        margin-left: 4px;
        img {
          width: 20px;
          height: 15px;
        }
      }
    }
    .nav-item:nth-child(3) {
      .nav-link {
        color: $color_14;
      }
    }
    .nav-item:nth-child(4) {
      .nav-link {
        color: $color_13;
      }
    }
    .nav-item:nth-child(5) {
      .nav-link {
        color: $color_12;
      }
    }
    .nav-item:nth-child(6) {
      .nav-link {
        color: $color_11;
      }
    }
    .nav-item:nth-child(7) {
      .nav-link {
        color: $color_10;
      }
    }
    .nav-item:nth-child(8) {
      .nav-link {
        color: $color_9;
      }
    }
    .nav-item:nth-child(9) {
      .nav-link {
        color: $color_8;
      }
    }
    .nav-item:nth-child(10) {
      .nav-link {
        color: $color_7;
      }
    }
    .nav-item:nth-child(11) {
      .nav-link {
        color: $color_3;
      }
    }
    .nav-link {
      text-decoration: none;
      margin: 0 15px;
      font-size: 13px;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
      }
    }
    .nav-item {
      display: flex;
      align-items: center;
    }
  }
  .header {
    &-wrapper {
      display: flex;
      .nav-right {
        list-style: none;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 12px;
        .nav-item {
          padding: 4px 0;
          &:nth-child(1) {
            img {
              width: 30px;
              height: 30px;
            }
          }
          &:nth-child(2) {
            img {
              width: 40px;
              height: 40px;
            }
          }
          .nav-link {
            text-decoration: none;
            margin: 0 8px;
          }
        }
        .nav-item {
          position: relative;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          .nav-link {
            white-space: nowrap;
            color: $color_3;
            transition: all 0.5s ease-in;
            &:hover {
              transform: scale(1.1);
              -webkit-transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}
.center_register-content {
  .content-wrapper {
    display: flex;
  }
  .center_register-management {
    background: $color_6;
    .navbar-wrapper {
      min-width: 265px;
    }
    .nav-main {
      margin-left: 22px;
      list-style: none;
      display: flex;
      flex-direction: column;
      .nav-item {
        display: inline-block;
        padding: 10px;
        border: 1px solid transparent;
        .text {
          margin-right: 10px;
        }
        &.active {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border: 1px solid $color_3;
          border-right: none;
          background: $color_2;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: $color_2;
            right: -1px;
            top: 0;
          }
        }
      }
      .nav-link {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 11px;
        color: $color_7;
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
        .number {
          margin-left: auto;
        }
      }
    }
    .tab-content {
      background: $color_2;
      min-width: 300px;
      border: 1px solid $color_3;
    }
  }
}
.center_register-form {
  .group-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 15px;

    .btn-primary {
      border-radius: 50px;
      margin: 0 5px;
      border: 1px solid $color_1;
      transition: all 0.1s ease-in;
      &:hover {
        border-color: $color_17;
        color: $color_7;
        transition: all 0.1s ease-in;
        .cls-3 {
          fill: $color_7;
          transition: all 0.1s ease-in;
        }
      }
      &:disabled,
      &[disabled] {
        background: $color_3;
        color: $color_2;
        border: 1px solid transparent;
        cursor: not-allowed;
        .cls-3 {
          fill: $color_2;
        }
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        transition: all 0.3s ease-in;
      }
    }
  }
  .center_register-tabs {
    position: relative;
    min-height: 200px;
    clear: both;
    margin: 30px 40px;
    background: white;
    padding-bottom: 88px;
    ul.tabs {
      margin: 0px;
      padding: 0px;
      list-style: none;
      flex-direction: row;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    ul.tabs li {
      font-size: 13px;
      background: $color_6;
      color: $color_7;
      display: inline-block;
      padding: 10px;
      cursor: pointer;
      border: 1px solid $color_16;
      text-align: center;
      margin-left: -1px;
    }

    ul.tabs li.current {
      background: $color_18;
      color: $color_7;
      border: 1px solid $color_16;
      // &:after {
      //     content: '';
      //     position: absolute;
      //     width: 100%;
      //     background: $color_6;
      //     bottom: -1px;
      //     border-bottom: 1px solid $color_6;
      //     left: 0;
      // }
    }

    .tab-pane {
      display: none;
      padding: 0px;
      margin-top: 20px;
      .table-wrapper,
      .group-bottom {
        margin: 20px 0;
      }
      .search-input {
        max-width: 400px;
        display: flex;
        margin-top: 10px;
        .form-input {
          border-radius: 50px;
          border: 1px solid $color_3;
          margin: 0;
          padding: 5px 10px;
        }
        .btn-primary {
          border-radius: 50px;
          margin: 0 5px;
          border: 1px solid $color_1;
          transition: all 0.1s ease-in;
          &:hover {
            border-color: $color_17;
            color: $color_7;
            transition: all 0.1s ease-in;
            .cls-3 {
              fill: $color_7;
              transition: all 0.1s ease-in;
            }
          }
          &:disabled,
          &[disabled] {
            background: $color_3;
            color: $color_2;
            border: 1px solid transparent;
            cursor: not-allowed;
            .cls-3 {
              fill: $color_2;
            }
          }
          svg {
            width: 16px;
            height: 16px;
            margin-right: 7px;
          }
        }
      }
      .button-slide {
        margin: 170px 0 80px 0;
        .btn-primary {
          margin: 0 48px;
        }
      }
    }

    .tab-pane.current {
      display: inherit;
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    position: fixed;
    z-index: 1030;
    left: 0;
    top: 0;
  }
  .confirmation {
    max-width: 518px;
    margin: 0 auto;
    border: 1px solid $color_16;
    background: $color_2;
    margin-bottom: 210px;
    position: relative;
    top: 30%;
    width: 80%;
    .confirm-heading {
      p {
        padding: 11px;
        color: $color_2;
        background: $color_16;
        font-size: 15px;
      }
    }
    &-content {
      display: flex;
      .img {
        padding: 20px;
        background: $color_1;
        img {
          width: 42px;
          height: 42px;
        }
      }
      .alert {
        p {
          letter-spacing: 0.05rem;
          line-height: 2.23;
          font-size: 13px;
          color: $color_7;
          margin: 20px 0 58px 16px;
          white-space: normal;
        }
        .group-button {
          margin-right: 0;
          margin-bottom: 30px;
        }
        button {
          margin-right: 50px;
        }
      }
    }
  }
  .form-main {
    max-width: 560px;
    margin: 25px;
    font-size: 11px;
    color: $color_7;
    .group-form {
      margin-top: 22px;
      width: 43%;
      white-space: normal;
    }
    .form-OperatingTime {
      width: 100%;
    }
    .group-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .address-wrapper {
      justify-content: start;
      align-items: flex-end;
    }
    .form-phone {
      margin-top: 22px;
      .select-phone {
        width: 14.5%;
      }
      .form-row {
        display: flex;
        align-items: center;
      }
      .selectphone {
        margin-top: 0;
        margin-left: 10px;
      }
    }
    .group-login {
      display: flex;
      align-items: flex-end;
      button img {
        width: 15px;
      }
      .btn-primary {
        border-radius: 50px;
        margin: 5px 18px;
        border: 1px solid $color_1;
        transition: all 0.1s ease-in;
        &:hover {
          border-color: $color_17;
          color: $color_7;
          transition: all 0.1s ease-in;
          .cls-3 {
            fill: $color_7;
            transition: all 0.1s ease-in;
          }
        }
        &:disabled,
        &[disabled] {
          background: $color_3;
          color: $color_2;
          border: 1px solid transparent;
          cursor: not-allowed;
          .cls-3 {
            fill: $color_2;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          margin-right: 7px;
          transition: all 0.1s ease-in;
        }
      }
    }
    .group-email {
      display: flex;
      align-items: center;
      .group-form:nth-child(2) {
        margin-left: 65px;
        .approve {
          margin-left: 15px;
        }
      }
      span {
        font-size: 11px;
        color: $color_7;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 17px;
        margin: 15px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color_2;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: '';
          height: 15px;
          width: 15px;
          left: -1px;
          bottom: -1px;
          background-color: $color_2;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }
      input:checked + .slider {
        background-color: $color_1;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px $color_1;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }
      .slider.round {
        border-radius: 34px;
        border: 1px solid $color_3;
      }
      .slider.round:before {
        border-radius: 50%;
        border: 1px solid $color_3;
      }
    }
    .custom-select {
      width: 100%;
      margin-top: 10px;
      cursor: pointer;
      background: #ffffff url(../images/Asset10@2x.png) right 0.75rem
        center/10px 10px no-repeat;
      &:focus {
        outline: none;
      }
    }
    input,
    select,
    textarea {
      padding: 9.5px;
      border: 1px solid $color_3;
      border-radius: 4px;
      margin-top: 10px;
      &:focus {
        outline: none;
      }
    }
    .system {
      margin-top: 30px;
      span {
        font-size: 11px;
        color: $color_7;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 17px;
        margin: 10px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color_2;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: '';
          height: 15px;
          width: 15px;
          left: -1px;
          bottom: -1px;
          background-color: $color_2;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }
      input:checked + .slider {
        background-color: $color_1;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px $color_1;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }
      .slider.round {
        border-radius: 34px;
        border: 1px solid $color_3;
      }
      .slider.round:before {
        border-radius: 50%;
        border: 1px solid $color_3;
      }
    }
  }
  .permission {
    position: relative;
    margin-top: 60px;
    span {
      background-color: $color_2;
      font-weight: normal;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.5em;
      border-top: 1px solid $color_16;
      z-index: -1;
    }
  }
  .form-heading {
    span {
      background-color: $color_2;
      font-weight: normal;
      font-size: 15px;
    }
  }
  .group-row {
    display: flex;
    .form-check:nth-child(2) {
      margin-left: 45px;
    }
  }
  .group-button {
    margin-top: 100px;
    justify-content: flex-end;
  }
  .group-btn-mb {
    margin-top: 10px;
  }
  .form-check {
    margin-top: 30px;
    .dropdown-common {
      // display: none;
      margin-left: 22px;
    }
    .check-submenu {
      display: flex;
      flex-direction: column;
      margin-left: 22px;
    }
    .label-check {
      display: inline-block;
      position: relative;
      cursor: pointer;
      font-size: 11px;
      user-select: none;
      padding-left: 23px;
      margin-bottom: 10px;
      img {
        margin-right: 10px;
        width: 10px;
      }
    }
    .label-check input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 13px;
      height: 13px;
      background: $color_2;
      border: 1px solid $color_16;
    }
    .label-check:hover .checkmark {
      background: #ccc;
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    .label-check .checkmark:after {
      top: 20%;
      left: 12%;
      width: 9px;
      height: 9px;
      background: url(../images/icon_checked.svg) no-repeat center center;
      background-size: 100% 100%;
    }
    .label-check input:checked ~ .checkmark {
      background: #2196f3;
      border: 1px solid transparent;
    }
    .label-check input:checked ~ .checkmark:after {
      display: block;
    }
  }
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 13px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  bottom: -118px;
  right: -11px;
  font-size: 13px;
}

.dropdown-content a {
  float: none;
  color: $color_3;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.nav-item:hover .dropdown-content {
  display: block;
}
@media screen and (max-width: 1024px) {
  .center_register-main {
    margin-left: 55px;
    .header-wrapper {
      flex-direction: row;
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .center_register-form .form-main {
    padding: 15px;
  }
  .content-wrapper {
    flex-direction: column;
    .center_register-management {
      flex-direction: row;
      .tab-content {
        min-width: 704px;
      }
      .nav-main {
        padding-bottom: 10px;
      }
    }
  }
  .center_register-form {
    margin-left: 0px;
    border: 1px solid $color_3;
  }
}
.sidebar-backdrop-p {
  display: none;
}
@media screen and (max-width: 992px) {
  .center_register-content {
    .center_register-form {
      .group-row {
        flex-direction: column;
        .form-check:nth-child(2) {
          margin-left: 0;
        }
      }
    }
    .center_register-management {
      .nav-main .nav-item.active:after {
        right: 0;
      }
    }
  }
}
@media screen and (max-width: 969px) {
  .sidebar-backdrop-p {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1029;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    transition: 0.3s;
    opacity: 0.5;
    &.show {
      display: block;
    }
  }
  .sidebar_register {
    &.sidebar-Collapse {
      width: 50%;
      z-index: 10;
    }
  }
  .center_register-main {
    margin-left: 55px;
    &.main-slide {
      margin-left: 0px;
      width: calc(100%);
    }
    .header-wrapper {
      flex-direction: column;
      .nav-right {
        margin: 0;
        justify-content: center;
      }
    }
    .nav-left {
      flex-direction: row;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .center_register-form .form-main {
    padding: 15px;
  }
  .content-wrapper {
    flex-direction: column;
    flex-wrap: wrap;

    .center_register-management {
      flex-direction: row;
      .tab-content {
        min-width: 650px;
      }
    }
  }
  .center_register-form {
    margin-left: 0px;
    border: 1px solid #939598;
  }
}
@media screen and (max-width: 768px) {
  .sidebar_register {
    &.sidebar-Collapse {
      width: 50%;
      z-index: 1030;
    }
  }
  .center_register-main {
    margin-left: 55px;
    &.main-slide {
      margin-left: 0px;
      width: calc(100%);
    }
    .header-wrapper {
      flex-direction: column;
      .nav-right {
        margin: 0;
        justify-content: center;
      }
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .center_register-form .form-main {
    padding: 15px;
  }
  .content-wrapper {
    flex-direction: column;
    .user_register-management {
      flex-direction: row;
      .tab-content {
        min-width: 458px;
      }
    }
  }
  .center_register-form {
    margin-left: 0px;
    border: 1px solid #939598;
    .center-tabs {
      margin-left: 10px;
      margin-right: 10px;
    }
    .center_tab-pane {
      .code_registration {
        .code-navbar {
          margin: 10px;
        }
        &-content {
          margin: 10px;
          width: auto;
          margin-bottom: 40px;
          .input-group {
            margin: 10px;
          }
          .group-form .group-button {
            margin-right: 0;
          }
        }
      }
    }
    .center_tab-pane .code_registration-content .group-form .result {
      margin-left: 0px;
    }
  }
}
.btn-collapse {
  display: none;
}
.dropdown-user {
  display: none;
  position: absolute;
  background-color: $color_2;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  font-size: 13px;
}
.table-wrapper {
  margin: 20px;
  overflow-x: auto;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  position: relative;
  .highlight td {
    background: $color_16;
  }
  tr {
    border: 1px solid $color_7;
    padding: 0.35em;
    th {
      background: $color_16;
    }
    td {
      padding: 8px 5px;
      // background: $color_2;
      &:nth-child(1) {
        text-align: center;
      }
    }
  }
  th {
    padding: 5px;
  }
  th,
  td {
    text-align: left;
    // border: 1px solid $color_7;
    // color: $color_7;
    // font-size: 13px;
    white-space: nowrap;
    .label-check {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        border: 1px solid $color_7;
      }
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark:after {
        left: 2px;
        bottom: 0px;
        width: 10px;
        height: 12px;
        background: url(../images/icon_checked2.svg) no-repeat center center;
        background-size: 100% 100%;
      }
    }
  }
}

@media screen and(max-width:739px) {
  .center_register-form {
    .center_tab-pane .code_registration {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 512px) {
  .center_register-form {
    .center_register-tabs {
      margin: 10px;
    }
    .confirmation-content {
      .alert button {
        margin-right: 10px;
      }
    }
    .code_registration {
      flex-direction: column;
    }
  }
  .admin {
    .sidebar_register {
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      &.sidebar-Collapse {
        margin-left: 0px;
      }
    }
  }
  .btn-collapse {
    display: block;
    color: $color_1;
    padding: 0;
  }
  .center_register-main {
    margin-left: 0;
    width: calc(100%);
    &.main-slide {
      margin-left: 0;
      width: calc(100%);
      position: absolute;
      .user_register-form {
        min-width: 375px;
      }
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
    .header-wrapper {
      .logo-mb {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        svg {
          display: block;
          width: 100px;
          margin: auto;
        }
        img {
          width: 40px;
        }
        .show .dropdown-user {
          display: block;
        }
        .dropdown-user {
          .dropdown-header {
            padding: 8px 20px;
            background-color: $color_15;
          }
          a {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0.8rem 1.6rem;
            white-space: nowrap;
            color: $color_3;
            text-decoration: none;
            img {
              width: 1rem;
              height: 1rem;
              margin-right: 10px;
            }
          }
        }
      }

      .nav-right {
        .nav-item {
          display: none;
        }
      }
    }
  }

  .center_register-form {
    .group-button {
      justify-content: center;
    }
    .center_register-tabs {
      .tab-pane .button-slide .btn-primary {
        margin: 0px 10px;
      }
    }
  }
  .user_register-form .form-main {
    padding: 15px;
  }
  .center_register-content {
    .content-wrapper {
      flex-direction: column;
      flex-wrap: wrap;
      white-space: normal;
      .center_register-management {
        flex-direction: column;
        min-width: 320px;
        .tab-content {
          min-width: 375px;
          height: 300px;
        }
      }
    }
  }
  .center_register-form {
    min-width: 320px;
    margin-left: 0px;
    border: 1px solid #939598;
    .group-btn-mb {
      flex-wrap: wrap;
      .btn {
        margin-bottom: 10px;
      }
    }
    .center-tabs {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
@media screen and (max-width: 424px) {
  .center_register-form {
    .center_register-tabs {
      margin: 10px;
      .table-wrapper {
        margin-left: 0;
        margin-right: 0;
      }
      .tabs {
        .tab-link {
          font-size: 11px;
          padding: 9.5px;
        }
      }
      .group-bottom {
        flex-direction: column;
      }
      .pagination {
        margin-top: 20px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .center_register-form {
    .group-button .btn-primary {
      margin: 0 4px;
    }
  }
}
