@import 'variable';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Cafe24Simplehae';
  src: url('./fonts/main/Cafe24Simplehae.woff') format('woff');
}
@font-face {
  font-family: 'Consolas';
  src: url('./fonts/main/consolas.woff') format('woff');
}
@font-face {
  font-family: 'Nanum Gothic';
  src: url('./fonts/main/NanumGothic.otf') format('otf');
}
@font-face {
  font-family: 'HANDotum';
  src: url('./fonts/main/HANDotum.ttf') format('ttf');
}
@font-face {
  font-family: 'HANDotumB';
  src: url('./fonts/main/HANDotumB.ttf') format('ttf');
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  font-family: 'Inter', sans-serif;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

#datepicker-div {
  position: absolute;
  display: none;
  background-color: #fff;
  z-index: 1031;
  top: 10px;
  overflow: hidden;
  left: 165px;
  &.show {
    display: block;
  }
  table {
    border-collapse: initial;
    font-size: 11px;
    border: 1px solid #939598;
    border-radius: 4px;
    margin-top: 5px;
    span {
      font-size: 11px;
    }
  }
  .datepicker-calendar {
    .datepicker-other-month a {
      color: #bcbec0;
    }
    tr {
      &:nth-child(2) {
        td {
          background: none;
        }
      }
      border: none;
    }
    thead th {
      text-align: center;
      font-weight: 400;
      color: #6d6e71;
      border: none;
      padding: 5px;
      background: #ffffff;
    }
    tbody {
      td {
        text-align: center;
        padding: 5px;
        border: none;
      }
    }
    tbody td a {
      display: block;
      transition: 0.3s all;
      color: #6d6e71;
      text-decoration: none;
    }
    tbody td a:hover {
      background-color: #e0f2f1;
    }
    tbody td a.ui-state-active {
      background-color: #00bfdf;
      color: white;
    }
  }
  .datepicker-header {
    a.ui-corner-all {
      cursor: pointer;
      position: absolute;
      top: 14px;
      width: 10px;
      height: 10px;
      transition: 0.3s all;
    }
    a.corner-all:hover {
      background-color: #eceff1;
    }
    a.datepicker-prev {
      left: 6px;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 50%;
      transform: rotate(180deg);
    }
    a.datepicker-next {
      right: 6px;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 50%;
    }
    a > span {
      display: none;
    }
  }
  .datepicker-title {
    text-align: center;
    font-size: 11px;
    padding: 12px;
    border: 1px solid #939598;
    border-radius: 4px;
    color: #6d6e71;
  }
  .datepicker-week-col {
    color: #6d6e71;
  }
}

.cell-link {
  cursor: pointer;
  color: #00bfdf;
}
.cell-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  color: #00bfdf;
}
.cell-error-text {
  color: red;
  font-size: 12px;
  font-style: italic;
}

.dx-datagrid tbody td.cell-can-edit {
  background-color: #eeeeee !important;
}

.dx-selection td {
  // background-color: $color_8 !important;
}

.icon-table-content {
  // width: 20px;
  // height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-error-help-text {
  .dx-field-item-help-text {
    color: red !important;
    font-size: 12px;
    font-style: italic;
  }
}

.product-image-grid-custom {
  width: 100%;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.last-btn-container-input-popup {
  .dx-texteditor-buttons-container:last-child {
    position: absolute;
    background-color: #fff;
    right: 0px;
    margin: 0;
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 6px !important;
  }
  .dx-textbox.dx-texteditor.dx-state-readonly {
    .dx-texteditor-buttons-container:last-child {
      background-color: unset;
    }
  }
}
