.btn {
  display: flex;
  font-weight: 400;
  color: $color_1;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 8px;
  font-size: 13px;
  line-height: 1.5;
  cursor: pointer;
  font-family: 'Naum Gothic', sans-serif;
}
.sidebar {
  background: $color_1;
  width: 146px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.5s ease;
  z-index: 1030;
  &-brand {
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
    img {
      width: 77px;
      height: 25px;
      margin-right: 20px;
    }
    .btn-sidebar {
      display: inline-block;
      background: none;
      border: none;
      color: $color_2;
      cursor: pointer;
      font-size: 20px;
      transition: 0.2s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  &-menu {
    li {
      width: 100%;
      text-align: center;
      a {
        color: $color_2;
        text-decoration: none;
        display: block;
        padding: 15px;
        &.active {
          background: $color_2;
          color: $color_1;
          svg {
            defs {
              .cls-1 {
                fill: $color_1;
              }
            }
          }
        }
      }
    }
    svg {
      margin-right: 10px;
      width: 15px;
      height: 20px;
    }
  }
}
.modal {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  position: fixed;
  z-index: 1030;
  left: 0;
  top: 0;
}
.alert-contact {
  margin: 150px 20px;
  .img {
    margin: 0 auto;
    width: 100px;
    img {
      width: 100%;
    }
  }
  .alert-text {
    font-size: 13px;
    text-align: center;
    color: $color_7;
    margin: 10px;
  }
  .group-button {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    .btn {
      border: 1px solid $color_1;
      border-radius: 40px;
      transition: all 0.1s ease-in;
      svg {
        width: 18px;
        margin-right: 10px;
        path {
          transition: all 0.1s ease-in;
        }
      }
      &:hover {
        color: $color_2;
        background: $color_7;
        border-color: transparent;
        svg {
          path {
            fill: $color_2;
          }
        }
      }
    }
  }
}
.sidebar-Collapse {
  width: 55px;
  .tab-link {
    svg {
      margin: 0;
    }
    span {
      display: none;
    }
  }
}

.btn-wrapper {
  text-align: center;
}
.main-content {
  transition: width 0.5s ease;
  margin-left: 146px;
  .form-content {
    position: relative;
    top: 20%;
    max-width: 760px;
    margin: 0 auto;
    border: 1px solid $color_3;
    background: $color_2;
    width: 80%;
    .heading {
      text-align: center;
      font-size: 20px;
      color: $color_7;
      margin: 10px 0;
    }
    .small-heading {
      font-size: 12.55px;
      color: $color_7;
      text-align: center;
      margin: 20px 0;
    }
    .form {
      margin: 25px 39px;
    }
    .form-row {
      justify-content: space-between;
    }
    .form-request {
      .form-input {
        margin-top: 10px;
        width: 100%;
        resize: none;
      }
    }
    .group-button {
      justify-content: center;
      display: flex;
      margin: 30px 0;
      .btn {
        border: 1px solid $color_1;
        border-radius: 40px;
        margin: 0 10px;
        svg {
          width: 18px;
          margin-right: 10px;
        }
      }
    }
  }
  .form {
    max-width: 680px;
    margin: 0 auto;
    label.error {
      color: $color_5;
      font-size: 1rem;
      display: block;
      margin-top: 15px;
    }
    &-row {
      display: flex;
      select {
        width: 165px;
        border-radius: 5px;
      }
    }
    &-phone {
      .selectphone {
        margin-left: 22px;
      }
    }
    &-footer {
      color: $color_3;
      text-align: center;
      margin-top: 50px;
      p {
        margin-bottom: 25px;
      }
    }
    &-wrapper {
      padding: 16px;
    }
    &-heading {
      text-align: center;
      color: $color_1;
      margin: 60px 0;
      font-size: 20px;
    }
    &-input {
      border-radius: 4px;
      border: 1px solid $color_3;
    }
    label {
      color: $color_3;
      margin-bottom: 10px;
    }
    &-pass {
      position: relative;
      img {
        width: 35px;
        height: 29px;
        // position: absolute;
        // right: 15px;
        // bottom: 40px;
      }
    }

    #company {
      display: none;
      transition: 0.5s ease-in-out;
    }
    &-country,
    &-role {
      width: 243px;
      display: flex;
      flex-direction: column;
      .custom-select {
        padding: 15px;
        width: 100%;
      }
    }
    .group-form {
      margin-bottom: 25px;
      img {
        margin-top: 20px;
        margin-left: -50px;
      }
    }
    .custom-select {
      background: $color_2 url(../images/Asset10@2x.png) right 0.75rem
        center/10px 10px no-repeat;
      border-color: $color_3;
      margin-top: 10px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .form-code {
      margin-bottom: 25px;
    }
  }
}
.main-slide {
  margin-left: 55px;
  // position: absolute;
}

ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
ul.tabs li {
  background: none;
  color: #222;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: 0.5s ease-in;
  span {
    color: $color_2;
    font-size: 18px;
    white-space: nowrap;
    transition: all 0.5s ease-out;
  }
}

button.disabled {
  cursor: not-allowed;
  &:hover {
    opacity: 1;
  }
}

ul.tabs li.active {
  background: $color_2;
  color: $color_1;
  span {
    color: $color_1;
  }
  svg {
    .cls-1 {
      fill: $color_1;
    }
  }
}

.tab-pane {
  display: none;
  background: $color_2;
  padding: 15px;
}

.tab-pane.active {
  display: inherit;
}

input[type='text'],
input[type='password'] {
  width: 100%;
  // padding: 15px;
  display: inline-block;
  // margin-top: 10px;
}

input[type='text']:focus,
input[type='password']:focus {
  outline: none;
}
.form-name,
.form-pass,
.form-email {
  input[type='text'],
  input[type='password'] {
    width: 100%;
    padding: 15px;
    display: inline-block;
    margin-top: 10px;
  }
}
.btn-register,
.btn-login,
.btn-confirm,
.btn-resend {
  display: inline-block;
  background-color: $color_3;
  color: $color_2;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 300px;
  margin-top: 50px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  transition: opacity 0.2s ease-in;
  &:hover {
    opacity: 0.7;
  }
}
.btn-confirm,
.btn-resend {
  margin-top: 60px;
  margin-bottom: 0;
}
.btn-resend {
  cursor: not-allowed;
  &:hover {
    opacity: 1;
  }
  &.enabled {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}
.button-send {
  margin: 25px 0 50px 0;
  display: inline-block;
  background-color: #939598;
  color: #ffffff;
  padding: 14px 20px;
  border: none;
  cursor: pointer;
  width: 300px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  transition: opacity 0.2s ease-in;
  &:hover {
    opacity: 0.7;
  }
}
.notice {
  color: $color_5;
  font-size: 14px;
}
.btn-login {
  margin-top: 162px;
}
.tooltip {
  margin: 14px 0;
  display: none;
  color: $color_5;
}
.btn-confirm,
.enabled {
  background: $color_1;
}
.descript {
  color: $color_3;
}
.disabled {
  background: $color_3;
}

.button-confirm {
  background: $color_3;
}
.logo-mb {
  svg {
    display: none;
  }
}
.sidebar-backdrop {
  display: none;
}
@media screen and (max-width: 992px) {
  .main-content .form-content .form-row {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .main-content {
    .form {
      max-width: 525px;
    }
    .form-row select {
      width: 95px;
    }
    .form-country .custom-select,
    .form-role .custom-select {
      width: 141%;
    }
  }
  .country-role {
    flex-direction: column;
    width: 100%;
  }
  .form {
    &-country {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}
@media screen and (max-width: 512px) {
  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1029;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    transition: 0.3s;
    opacity: 0.5;
    &.show {
      display: block;
    }
  }
  .admin {
    display: flex;
    flex-direction: row;
    .sidebar {
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      margin-left: -146px;
      &-Collapse {
        margin: 0;
        width: 50%;
        z-index: 1030;
        .sidebar-brand {
          align-items: center;
          img {
            display: block;
          }
        }
        .tab-link {
          span {
            display: block;
            margin-left: 10px;
          }
        }
      }
    }
    .main-content {
      margin: 0;
      .logo-mb {
        display: flex;
        justify-content: start;
        padding: 15px;
        svg {
          display: block;
          width: 100px;
          margin: auto;
        }
      }
    }
    .country-role {
      flex-direction: column;
      width: 100%;
    }
  }
  .form {
    .group-form {
      margin-bottom: 20px;
    }
    &-country {
      margin-bottom: 20px;
      width: 100%;
    }
    &-role {
      width: 100%;
    }
    .btn-register,
    .btn-login {
      margin-top: 20px;
    }
  }
  ul.tabs {
    display: flex;
    flex-direction: column;
  }
}
#tooltip-pass {
  color: $color_5;
  // display: none;
}

.selectphone {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.align-flex-start {
  align-items: flex-start !important;
}

.select-phone-no {
  padding-right: 15px !important;
  background: #ffffff url(../images/Asset10@2x.png) right 0.25rem center/10px
    10px no-repeat !important;
  &:focus {
    outline: none;
  }
}

.user-registration-textarea {
  width: 100%;
  &:focus {
    outline: none;
  }
}

.form-content {
  position: relative;
  top: 20%;
  max-width: 760px;
  margin: 0 auto;
  border: 1px solid $color_3;
  background: $color_2;
  width: 80%;
  label {
    color: #939598;
    margin-bottom: 10px;
  }
  .heading {
    text-align: center;
    font-size: 20px;
    color: $color_7;
    margin: 10px 0;
  }
  .small-heading {
    font-size: 12.55px;
    color: $color_7;
    text-align: center;
    margin: 20px 0;
  }
  .form {
    margin: 25px 39px;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
  }
  .form-request {
    margin-top: 20px;
  }
  .form-input {
    border-radius: 4px;
    border: 1px solid #939598;
    margin-top: 10px;
    width: 100%;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  .group-button {
    justify-content: center;
    display: flex;
    margin: 30px 0;
    .btn {
      border: 1px solid $color_1;
      border-radius: 40px;
      margin: 0 10px;
      svg {
        width: 18px;
        margin-right: 10px;
      }
    }
  }
}
.label-error{
  color: red;
  margin-top: 15px;
  margin-bottom: 10px;
}

.form-input-password {
  padding-right: 35px!important;
}

.column-data {
  color: $color_1;
  cursor: pointer;
}
.column-data.dx-datagrid-action {
  color: $color_7;
}
.column-hover-cursor {
  cursor: pointer;
}