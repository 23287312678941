

$color_1:#00BFDF;
$color_2:#FFFFFF;
$color_3:#939598;
$color_4:#02B9A4;
$color_5:#ED1C24;
$color_6:#F1F2F2;
$color_7:#6D6E71;
$color_8:#7D98BB;
$color_9:#676291;
$color_10:#966B92;
$color_11:#B76E11;
$color_12:#9B811C;
$color_13:#66952E;
$color_14:#00A0BB;
$color_15:#ebedef;
$color_16:#BCBEC0;
$color_17: #D1D3D4;
$color_18:#02878e8c;
$color_19:#EFF9FE;

// variables icon font
$icomoon-font-family: "smdc" !default;
$icomoon-font-path: "./fonts/icon" !default;

$icon-approve: "\e900";
$icon-bars: "\e901";
$icon-calculate: "\e902";
$icon-cancel1: "\e903";
$icon-cancel2: "\e904";
$icon-check: "\e906";
$icon-close: "\e907";
$icon-code1: "\e908";
$icon-common: "\e90a";
$icon-create: "\e90d";
$icon-delete: "\e90e";
$icon-delivery: "\e90f";
$icon-direct: "\e910";
$icon-duplication: "\e911";
$icon-excel: "\e913";
$icon-fix: "\e914";
$icon-goods: "\e915";
$icon-import: "\e916";
$icon-inventory: "\e917";
$icon-logistics: "\e919";
$icon-next: "\e91b";
$icon-ok: "\e91c";
$icon-operation: "\e91d";
$icon-option: "\e91e";
$icon-order: "\e91f";
$icon-padlock: "\e920";
$icon-pen: "\e921";
$icon-pin: "\e922";
$icon-pos: "\e923";
$icon-preview: "\e924";
$icon-print: "\e925";
$icon-Profile: "\e926";
$icon-program: "\e927";
$icon-receive: "\e928";
$icon-refresh: "\e929";
$icon-reject: "\e92a";
$icon-repair: "\e92b";
$icon-ring: "\e92d";
$icon-save: "\e92e";
$icon-search1: "\e92f";
$icon-search2: "\e930";
$icon-send: "\e931";
$icon-setting: "\e932";
$icon-star: "\e933";
$icon-system: "\e934";
$icon-transction: "\e935";
$icon-update: "\e936";

