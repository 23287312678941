@import '_base';
@import '_variable';
@import '_main';
@import '_program';
@import '_user';
@import '_center';
@import '_member';
@import '_centerZone';
@import '_management';
@import '_icon';
@import '_font';

.custom-color-primary {
  color: #00bfdf !important;
}
.custom-color-secondary {
  color: #939598;
}
.custom-color-white {
  color: #f1f2f2;
}
.custom-bg-primary {
  background-color: #00bfdf;
}
.custom-bg-secondary {
  background-color: #939598;
}
.custom-bg-white {
  background-color: #f1f2f2;
}

.dx-toast-message {
  white-space: pre-line;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pt-20 {
  padding-top: 15px !important;
}
.px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.class-test .dx-button-content {
  padding-right: 3px !important;
  padding-left: 3px !important;
  .dx-button-text {
    color: #7f56d9;
  }
}

.default-input-language .dx-state-focused .dx-texteditor-container {
  background-color: #ffffd5 !important;
  border-radius: 8px !important;
}

.popup-product-selected-button {
  .dx-button-content {
    background-color: #dbdbdb;
  }
}
// css for only import excel grid (1047.1,...)
.import-excel-grid-class {
  .dx-datagrid .dx-data-header.dx-row > td {
    white-space: break-spaces !important;
  }
  .dx-datagrid .dx-data-row.dx-row > td {
    max-width: 600px;
    min-width: 130px;
    // white-space: break-spaces !important;
  }
}
//
.form-group-padding-custom {
  .dx-form-group-with-caption > .dx-form-group-content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

// tab panel not padding content
.tab-panel-not-padding-content {
  .dx-tabpanel-container {
    .dx-multiview-wrapper {
      padding: 0px !important;
    }
  }
}
.btn-edit-icon-purple {
  .dx-button-content {
    .dx-icon {
      color: #7f56d9;
    }
  }
}
