.popup-order-custom {
    .dx-tabpanel-container {
        .dx-multiview-wrapper {
            padding-top: 5px !important;
        }
    }

    .dx-form-group-with-caption>.dx-form-group-content {
        padding: 0 !important;
    }

    .dx-column-indicators {
        float: unset !important;
    }

    .dx-datagrid-action.dx-cell-focus-disabled {
        vertical-align: middle !important;
    }
}

.popup-order-custom-claim {
    .dx-form-group-with-caption>.dx-form-group-content {
        padding: 0 !important;
    }
}

.datagird-payment-info {
    .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection {
        position: absolute;
        top: -45px;
        background-color: transparent;
    }

    .dx-datagrid-header-panel {
        height: 10px;
    }
}