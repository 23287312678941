.InputFileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dropzone-external-upload {
    .dx-fileuploader-files-container {
      display: none !important;
    }
    .dx-fileuploader-button.dx-fileuploader-upload-button {
      display: none !important;
    }
  }
  .custome-view-image {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    .image-view {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-position: center;
      border-radius: 5px;
    }
    .no-image {
      position: absolute;
    }
  }
  .btn-preview {
    margin-left: 20px;
  }
}
