.my-page-popup {
  position: relative;

  .my-page-content-wrap {
    height: calc(80vh - 200px);
    // position: relative;

    .my-page-content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .my-page-content-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 49%;
        border: 1px solid #e1e1e1;
        padding: 20px;
        border-radius: 4px;
        min-height: 250px;

        .title {
          cursor: pointer;
          text-decoration: underline;
        }

        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .edit-btn {
          align-self: flex-start;
          margin-top: auto;
        }

        .open-new-tab-btn {
          align-self: flex-start;
          margin-top: auto;
        }

        .image-wrap {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }

    .my-page-btn-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      position: absolute;
      bottom: 20px;
      width: 100%;
    }
  }
}
