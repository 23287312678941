.collapse-form-search {
  margin-bottom: 20px;
  .custom-form-search-tabpanel {
    .dx-tabs {
      border-radius: 5px !important;
    }
    .dx-tabpanel-container {
      .dx-multiview-wrapper {
        border-radius: 5px !important;
      }
    }
  }
  .collapse-form-search-collapse-btn {
    display: flex;
    justify-content: flex-end;

    & i {
      font-size: 30px !important;
      width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
    }
  }
  .dx-icon.dx-icon-search {
    font-size: 14px;
  }
  .collapse-form {
    padding: 0 30px 30px;
  }
  .collapse-form-d-none {
    display: none;
  }
}
