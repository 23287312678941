.file-name {
  margin-left: 70px;
  margin-top: 20px;
  display: block;
}

.btn-group {
  margin-top: 50px;
  text-align: center;

  .btn-send {
    margin-right: 20px;
  }
}
