.tab1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    // max-width: 200px;
    overflow: hidden;
  }

  .w-25 {
    width: 25%;
  }

  .w-20 {
    width: 20%;
  }

  .w-50 {
    width: 50%;
  }

  .w-75 {
    width: 75%;
  }

  .w-80 {
    width: 80%;
  }

  .w-100 {
    width: 100%;
  }

  .h-25 {
    height: 25%;
  }

  .h-40 {
    height: 40%;
  }

  .h-50 {
    height: 50%;
  }

  .h-75 {
    height: 75%;
  }

  .h-100 {
    height: 100%;
  }

  .p-10 {
    padding: 10px !important;
  }

  .pt-20 {
    padding-top: 20px !important;
  }

  .d-flex {
    display: flex;
  }

  .f-wrap {
    flex-wrap: wrap;
  }
}

.chart-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: unset !important;
  width: unset !important;
}

.row-bullet {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  .bullet-name {
    width: 40%;
    // max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .bullet-value {
    width: 60%;
  }
}

.sale-by-year-and-quarter-table {
  overflow: auto;

  table {
    * {
      border: unset;
      background-color: transparent;
    }
  }

  table>tr>th {
    text-align: center;
  }
}

.chart-pick-carry {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

#quarter-treemap {
  height: calc(100% - 50px);
  overflow: hidden;
}

.dashboard-loadindicator .dx-loadindicator-wrapper {
  background-color: lightblue;
}