.themePro-custom-tab-panel {
  .dx-tabs {
    border-radius: 5px !important;
  }
  .dx-tabpanel-container {
    .dx-multiview-wrapper {
      border-radius: 5px !important;
      padding: 0 !important;
    }
  }
}

.normal-custom-tab-panel {
  .dx-tabpanel-container {
    .dx-multiview-wrapper {
      padding: 15px !important;
    }
  }
}