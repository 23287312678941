.wrap-list-program-menu {
  position: relative;
  padding-bottom: 80px;
  .list-program {
    max-height: 100%;
    overflow: hidden;
    height: 100%;
    .dx-scrollview-bottom-pocket {
      display: none;
    }
    .dx-list-item-content {
      padding: 0;
    }
    .dx-list-item.dx-state-focused {
      background-color: transparent;
      color: unset;
    }
  }
  .item-render {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    .icon-type,
    .icon-history img {
      width: 13px;
    }
    .dx-button-content {
      padding-right: 0px;
    }
    & > div:first-child {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      max-width: 200px;
    }
    & > div:last-child {
      display: flex;
      align-items: center;
    }
  }
  .save-bookmark {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}

.program-content {
  .content-wrapper {
    .program-management {
      min-width: auto;
      .dx-scrollable-content {
        padding-right: 0px !important;
      }
      .dx-item.dx-list-item {
        display: flex;
        align-items: center;
      }
      .dx-item-content.dx-list-item-content {
        order: 1;
      }
      .dx-list-item-after-bag.dx-list-reorder-handle-container {
        order: 0;
        padding-top: 5px;
      }
    }
    .register-program {
      position: relative;
      .bread-crumb {
        position: absolute;
        left: 50px;
      }
    }
    .bread-crumb {
      display: flex;
      align-items: center;
      padding: 5px 15px;
      position: sticky;
      top: 0;
      z-index: 9;
    }
  }
  #helpBtn {
    .dx-icon-help {
      color: #00bfdf;
    }
  }
}
