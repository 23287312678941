.sign-up {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 700px;
  margin: auto;
  .content {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 24px;
    .title {
      margin: 50px;
      display: flex;
      justify-content: center;
      font-weight: bold;
    }
    .form-sign-up {
      margin: 100px 0 0 0;
      width: 100%;
    }
    .btn-submit {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .btn-sign-up {
      margin-top: 20px;
      width: 200px;
    }
  }
  .footer {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    bottom: 40px;
    width: 100%;
    padding: 24px;
    left: 10%;
  }
}

.sign-up .mobile {
  .content {
    padding: 24px;
    .title {
      margin: 24px;
    }
    .form-sign-up {
      margin: 24px 0 0 0;
      width: 100%;
    }
    .btn-sign-up {
      margin-top: 24px;
      width: 100% !important;
    }
  }
}
