.center_register-form {
    min-height: 900px;
    .member_register-tabs {
        .registration-content {
            .heading {
                margin: 7px 30px;
                display: flex;
                color: $color_3;
                align-items: center;
                span {
                    font-size: 11px;

                    display: flex;
                    align-items: center;
                    img {
                        width: 15px;
                        margin-left: 5px;
                    }
                }
            }
            .group-form {
                display: flex;
                margin: 15px 35px;
                .input-wrapper {
                    margin-top: 5px;
                }
            }
            .form-input {
                margin: 0;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid $color_3;
                color: $color_3;
            }
            .form-zone {
                margin-right: 20px;
            }
            .group-flex {
                display: flex;
                justify-content: center;
            }
            .form-button {
                justify-content: space-between;
                align-items: center;
                .group-button {
                    margin-top: 0;
                }
            }
            .search-input .btn-primary {
                border-radius: 50px;
                margin: 0 5px;
                // border: 1px solid #00BFDF;
                transition: all 0.1s ease-in;
                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 7px;
                }
            }
        }
        .pagination {
            .prev, .next {
                .page-link img {
                    width: 13px;
                    margin-right: 10px;
                }
            }
        }
        .table-wrapper {
            table {
                .label-radio {
                    display: block;
                    position: relative;
                    padding-left: 15px;
                    cursor: pointer;
                    font-size: 11px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    color: $color_7;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    .checkmark {
                        position: absolute;
                        top: -7px;
                        left: 0;
                        height: 12px;
                        width: 12px;
                        border: 1px solid $color_16;
                        border-radius: 50%;
                    }
                    &:hover input ~ .checkmark {
                        background-color: $color_16;
                    }
                    input:checked ~ .checkmark {
                        background-color: $color_16;
                    }
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    input:checked ~ .checkmark:after {
                        display: block;
                    }
                    .checkmark:after {
                        top: 2px;
                       left: 2px;
                       width: 6px;
                       height: 6px;
                       border-radius: 50%;
                       background: $color_2;
                   }
                }
            }
        }
        .modal-location {
            .confirmation {
                max-width: 710px;
                .confirmation-content {
                    display: block;
                    table tr td:nth-child(1), table tr td:nth-child(2) {
                        text-align: left;
                    }
                    table tr td:nth-child(3), table tr td:nth-child(5) {
                        text-align: right;
                    }
                    table tr td:nth-child(6), table tr td:nth-child(7) {
                        text-align: center;
                    }
                    .group-button {
                        margin-top: 50px;
                        justify-content: center;
                    }
                }
            }
        }
        .modal-locationView {
            z-index: 1033;
            .confirmation {
                top: 6%;
            }
            .alert {
                width: 100%;
                .form {
                    margin: 20px;
                }
            }
            .form-row {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
            }
            .form-col {
                max-width: 190px;
                .form-flex {
                    position: relative;
                     a {
                        position: absolute;
                        top: 3px;
                        right: 3px;
                        svg {
                            width: 20px;
                        }
                    }
                }
            }
            textarea {
                width: 100%;
                resize: none;
            }
            .group-button {
                margin-top: 20px;
            }
            .switch {
                position: relative;
                display: inline-block;
                width: 32px;
                height: 17px;
                margin: 8px 15px;
                input { 
                    opacity: 0;
                    width: 0;
                    height: 0;
                  }   
            }
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color_2;
                -webkit-transition: .4s;
                transition: .4s;
                &:before {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    left: -1px;
                    bottom: -1px;
                    background-color: $color_2;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
                
            }
            input:checked + .slider {
                background-color: $color_1;
            }
              
            input:focus + .slider {
                box-shadow: 0 0 1px $color_1;
            }
              
            input:checked + .slider:before {
                -webkit-transform: translateX(15px);
                -ms-transform: translateX(15px);
                transform: translateX(15px);
            }
            .slider.round {
                border-radius: 34px;
                border: 1px solid $color_3;
            }
            .slider.round:before {
                border-radius: 50%;
                border: 1px solid $color_3;
            }
            .flex-start {
                justify-content: start;
            }
            .form-default {
                .label {
                    margin-left: 15px;
                }
            }
        }
        .modal-zoneInfo {
            .alert {
                width: 100%;
                padding: 8px;
                display: grid;
            }
            .button-wrapper {
                display: flex;
                justify-content: center;
                margin: 15px 0;
                .btn-primary {
                    border-radius: 50px;
                    margin: 0 5px;
                    // border: 1px solid #00BFDF;
                    transition: all 0.1s ease-in;
                    svg {
                        width: 18px;
                        margin-right: 10px;
                    }
                }
            }
            .group-button {
                margin: 10px 0;
                .btn {
                    margin: 0 10px;
                }
            }
            .confirmation {
                max-width: 820px;
                top: 6%;
            }
            .form-row {
                margin-bottom: 20px;
                display: flex;
            }
            .form-request {
                margin: 0 10px;
            }
            .form-col {
                max-width: 190px;
                margin: 0 10px;
                .form-flex {
                    position: relative;
                     a {
                        position: absolute;
                        top: 3px;
                        right: 3px;
                        svg {
                            width: 20px;
                        }
                    }
                }
            }
            .form-default {
                .form-flex {
                    display: flex;
                    align-items: center;
                }
            }
            textarea {
                width: 100%;
                resize: none;
            }
            .group-button {
                margin-top: 20px;
            }
            .switch {
                position: relative;
                display: inline-block;
                width: 32px;
                height: 17px;
                margin: 8px 15px;
                input { 
                    opacity: 0;
                    width: 0;
                    height: 0;
                  }   
            }
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color_2;
                -webkit-transition: .4s;
                transition: .4s;
                &:before {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    left: -1px;
                    bottom: -1px;
                    background-color: $color_2;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
                
            }
            input:checked + .slider {
                background-color: $color_1;
            }
              
            input:focus + .slider {
                box-shadow: 0 0 1px $color_1;
            }
              
            input:checked + .slider:before {
                -webkit-transform: translateX(15px);
                -ms-transform: translateX(15px);
                transform: translateX(15px);
            }
            .slider.round {
                border-radius: 34px;
                border: 1px solid $color_3;
            }
            .slider.round:before {
                border-radius: 50%;
                border: 1px solid $color_3;
            }
            .flex-start {
                justify-content: start;
            }
            .form-default {
                .label {
                    margin-left: 15px;
                }
            }
        }
        .modal-selectType {
            z-index: 1050;
            .alert {
                width: 100%;
            }
            .group-button {
                margin-top: 20px;
                justify-content: center;
                button {
                    margin: 0 15px;
                }
            }
            .radio_check-wrapper {
                margin: 20px;
                height: 168px;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                scrollbar-width: auto;
              scrollbar-color: #d1d3d4 #ffffff;
              &::-webkit-scrollbar {
                width: 12px;
                margin-top: 20px;
                padding: 0 2px;
              }

              &::-webkit-scrollbar-track {
                background: #ffffff;
                margin-top: 10px;
                border: 1px solid #d1d3d4;
                border-radius: 4px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #d1d3d4;
                border-radius: 10px;
                margin: 0px 2px;
                width: 8px;
              }
              .label-check {
                display: block;
                position: relative;
                padding-left: 15px;
                cursor: pointer;
                font-size: 11px;
                margin-bottom: 10px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: $color_7;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 12px;
                    width: 12px;
                    border: 1px solid $color_16;
                    border-radius: 50%;
                }
                &:hover input ~ .checkmark {
                    background-color: $color_16;
                }
                input:checked ~ .checkmark {
                    background-color: $color_16;
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                input:checked ~ .checkmark:after {
                    display: block;
                }
                .checkmark:after {
                    top: 2px;
                   left: 2px;
                   width: 6px;
                   height: 6px;
                   border-radius: 50%;
                   background: $color_2;
               }
              }
            }
        }
    }
}
@media screen and (max-width: 640px) {
    .center_register-form {
        .member_register-tabs {
            .registration-content {
                .modal-locationView {
                    .form-row {
                        flex-direction: column;
                        .form-col {
                            width: 100%;
                            max-width: none;
                        }
                    }
                }
                .modal-location {
                    .pagination {
                        flex-wrap: wrap;
                        justify-content: center;
                        .page-item {
                            margin: 5px 0;
                        }
                    }
                }
                .modal-zoneInfo {
                    .group-bottom {
                        justify-content: center;
                    }
                    .pagination {
                        flex-wrap: wrap;
                        justify-content: center;
                        .page-item {
                            margin: 5px 0;
                        }
                    }
                }
            } 
        } 
    } 

}
@media screen and (max-width: 536px) {
    .center_register-form {
        .member_register-tabs {
            .registration-content {
                .modal-zoneInfo {
                    .form-row {
                        flex-direction: column;
                        .form-col {
                            max-width: none;
                            margin-bottom: 10px;
                            .form-flex {
                                .switch-wrapper {
                                    display: flex;
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                    .group-button {
                        .btn {
                            margin: 5px;
                        }
                    }
                    .group-bottom {
                        margin: 20px 0;
                        align-items: center;
                    }
                    .pagination {
                        flex-wrap: wrap;
                        justify-content: center;
                        .page-item {
                            margin: 5px 0;
                        }
                    }
                }
            } 
        } 
    } 

}
@media screen and (max-width: 412px) {
    .center_register-form {
        .member_register-tabs {
            .registration-content {
                .form-button ,.group-bottom{
                    flex-direction: column;
                    align-items: flex-start;
                    .group-button {
                        margin-top: 10px;
                    }
                    .pagination {
                        margin-top: 15px;
                    }
                }
            } 
        } 
    } 

}