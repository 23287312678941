.pure-collapse-form-search {
  margin-bottom: 15px;

  .pure-collapse-form-collapse-btn {
    display: flex;
    justify-content: flex-end;

    & i {
      font-size: 30px !important;
      width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
    }
  }
  .pure-dx-icon.dx-icon-search {
    font-size: 14px;
  }
  .pure-collapse-form {
    padding: 30px 30px 30px;
    border: solid 0.5px #b1aeae;
  }
  .pure-collapse-form-d-none {
    display: none;
  }
}
