@import "variable";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rq4qfi');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rq4qfi#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rq4qfi') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rq4qfi') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rq4qfi##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-approve {
  &:before {
    content: $icon-approve; 
  }
}
.icon-bars {
  &:before {
    content: $icon-bars; 
  }
}
.icon-calculate {
  &:before {
    content: $icon-calculate; 
  }
}
.icon-cancel1 {
  &:before {
    content: $icon-cancel1; 
  }
}
.icon-cancel2 {
  &:before {
    content: $icon-cancel2; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-code1 {
  &:before {
    content: $icon-code1; 
  }
}
.icon-common {
  &:before {
    content: $icon-common; 
  }
}
.icon-create {
  &:before {
    content: $icon-create; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-delivery {
  &:before {
    content: $icon-delivery; 
  }
}
.icon-direct {
  &:before {
    content: $icon-direct; 
  }
}
.icon-duplication {
  &:before {
    content: $icon-duplication; 
  }
}
.icon-excel {
  &:before {
    content: $icon-excel; 
  }
}
.icon-fix {
  &:before {
    content: $icon-fix; 
  }
}
.icon-goods {
  &:before {
    content: $icon-goods; 
  }
}
.icon-import {
  &:before {
    content: $icon-import; 
  }
}
.icon-inventory {
  &:before {
    content: $icon-inventory; 
  }
}
.icon-logistics {
  &:before {
    content: $icon-logistics; 
  }
}
.icon-next {
  &:before {
    content: $icon-next; 
  }
}
.icon-ok {
  &:before {
    content: $icon-ok; 
  }
}
.icon-operation {
  &:before {
    content: $icon-operation; 
  }
}
.icon-option {
  &:before {
    content: $icon-option; 
  }
}
.icon-order {
  &:before {
    content: $icon-order; 
  }
}
.icon-padlock {
  &:before {
    content: $icon-padlock; 
  }
}
.icon-pen {
  &:before {
    content: $icon-pen; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-pos {
  &:before {
    content: $icon-pos; 
  }
}
.icon-preview {
  &:before {
    content: $icon-preview; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-Profile {
  &:before {
    content: $icon-Profile; 
  }
}
.icon-program {
  &:before {
    content: $icon-program; 
  }
}
.icon-receive {
  &:before {
    content: $icon-receive; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-reject {
  &:before {
    content: $icon-reject; 
  }
}
.icon-repair {
  &:before {
    content: $icon-repair; 
  }
}
.icon-ring {
  &:before {
    content: $icon-ring; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1; 
  }
}
.icon-search2 {
  &:before {
    content: $icon-search2; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-setting {
  &:before {
    content: $icon-setting; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-system {
  &:before {
    content: $icon-system; 
  }
}
.icon-transction {
  &:before {
    content: $icon-transction; 
  }
}
.icon-update {
  &:before {
    content: $icon-update; 
  }
}

