.admin {
  position: relative;
}
.sidebar_register {
  background: $color_1;
  width: 55px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  .sidebar-brand {
    padding: 6px;
  }
  &.sidebar-Collapse {
    width: 146px;
  }
  &-menu {
    svg {
      width: 20px;
      height: 20px;
      path {
        &.cls-2 {
          fill: $color_1;
        }
      }
    }
    .tab-link {
      padding: 15px;
      // border: 1px solid $color_1;
      border-top: 1px solid $color_1;

      justify-content: center;
      &.active {
        background: $color_6;
        border-right: none;
      }
    }
  }
  .tab-search {
    background: $color_6;
  }
}
.program-main {
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  width: calc(100% - 55px);
  position: absolute;
  top: 0;
  header {
    width: 100%;
    position: sticky;
    top: 0;
    // background: $color_2;
    z-index: 1029;
    .logo-mb {
      display: none;
    }
  }
  &.main-slide {
    margin-left: 146px;
    width: calc(100% - 146px);
    position: absolute;
    .program-form {
      min-width: 633px;
      transition: all 0.5s ease-in-out;
    }
  }
  .nav-left {
    padding: 15px 0;
    list-style: none;
    display: flex;
    .nav-item:nth-child(1) {
      .nav-link {
        color: $color_1;
      }
    }
    .nav-item:nth-child(2) {
      .nav-link {
        color: $color_13;
      }
    }
    .nav-item:nth-child(3) {
      .nav-link {
        color: $color_12;
      }
    }
    .nav-item:nth-child(4) {
      .nav-link {
        color: $color_11;
      }
    }
    .nav-item:nth-child(5) {
      .nav-link {
        color: $color_12;
      }
    }
    .nav-item:nth-child(6) {
      .nav-link {
        color: $color_11;
      }
    }
    .nav-item:nth-child(7) {
      .nav-link {
        color: $color_10;
      }
    }
    .nav-item:nth-child(8) {
      .nav-link {
        color: $color_9;
      }
    }
    .nav-item:nth-child(9) {
      .nav-link {
        color: $color_8;
      }
    }
    .nav-item:nth-child(10) {
      .nav-link {
        color: $color_7;
      }
    }
    .nav-item:nth-child(11) {
      .nav-link {
        color: $color_3;
      }
    }
    .nav-link {
      text-decoration: none;
      margin: 0 15px;
      font-size: 13px;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
      }
    }
    .nav-item {
      display: flex;
      align-items: center;
      text-decoration: none;
      &.active {
        span {
          font-weight: bold;
          font-size: 17px !important;
        }
      }
    }
  }
  .header {
    &-wrapper {
      display: flex;
      .nav-right {
        list-style: none;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 12px;
        .nav-item {
          padding: 4px 0;
          &:nth-child(1) {
            img {
              width: 30px;
              height: 30px;
            }
          }
          &:nth-child(2) {
            img {
              width: 40px;
              height: 40px;
            }
          }
          .nav-link {
            text-decoration: none;
            margin: 0 8px;
          }
        }
        .nav-item {
          position: relative;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          .nav-link {
            white-space: nowrap;
            color: $color_3;
            transition: all 0.5s ease-in;
            &:hover {
              transform: scale(1.1);
              -webkit-transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}
.program-content {
  height: calc(100vh - 51px);
  overflow: auto;
  .content-wrapper {
    display: flex;
  }
  .program-management {
    background: $color_6;
    min-width: 560px;
    display: flex;
    .navbar-wrapper {
      min-width: 265px;
      height: calc(100vh - 51px);
      transition: all 0.3s ease-in;
    }
    .program-title {
      display: flex;
      margin-left: 16px;
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 13px;
      color: $color_7;
      svg {
        width: 20px;
        margin-right: 6px;
      }
    }
    .nav-main {
      margin-left: 22px;
      list-style: none;
      display: flex;
      flex-direction: column;
      .nav-item {
        display: inline-block;
        padding: 8px;
        .text {
          margin-right: 10px;
        }
        &.active {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border: 1px solid $color_3;
          border-right: none;
          background: $color_2;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: $color_2;
            right: -1px;
            top: 0;
          }
        }
      }
      .nav-link {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 11px;
        color: $color_7;
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
        .number {
          margin-left: auto;
        }
      }
    }
    .tab-content {
      background: $color_2;
      min-width: 300px;
      border: 1px solid $color_3;
      .menu-list {
        list-style: none;
        padding: 20px;
        .menu-item {
          margin-top: 30px;
          > a {
            text-decoration: none;
            font-size: 12.55px;
            color: $color_7;
            display: flex;
            align-items: center;
            img {
              width: 10px;
              margin-right: 10px;
              transform: rotate(-90deg);
              &.rotate {
                transform: rotate(0);
              }
            }
          }
        }

        .submenu {
          list-style: none;
          padding: 0 10px;
          display: none;
          img {
            width: 10px;
            margin-right: 10px;
            transform: rotate(-90deg);
            &.rotate {
              transform: rotate(0);
            }
          }
          &.showMenu {
            display: block;
          }
          li {
            margin: 10px 0;
            a {
              text-decoration: none;
              font-size: 12.55px;
              color: $color_7;
              padding: 2px;
            }
          }
          .menu-child {
            list-style: none;
            padding: 0 10px;
            display: none;
            white-space: nowrap;
            img {
              width: 12px;
              margin-right: 10px;
              transform: rotate(-90deg);
              &.rotate {
                transform: rotate(0);
              }
            }
            &.showMenu {
              display: block;
            }
          }
        }
      }
    }
  }
  .program-form {
    min-width: 720px;
    margin-left: 10px;
    border: 1px solid $color_3;
    .group-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      margin-right: 15px;

      .btn-primary {
        border-radius: 50px;
        margin: 0 5px;
        border: 1px solid $color_1;
        transition: all 0.1s ease-in;
        &:hover {
          border-color: $color_17;
          color: $color_7;
          transition: all 0.1s ease-in;
          .cls-3 {
            fill: $color_7;
            transition: all 0.1s ease-in;
          }
        }
        &:disabled,
        &[disabled] {
          background: $color_3;
          color: $color_2;
          border: none;
          cursor: not-allowed;
          .cls-3 {
            fill: $color_2;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          margin-right: 7px;
          transition: all 0.1s ease-in;
        }
      }
    }
    .modal {
      .confirmation {
        max-width: 518px;
        margin: 0 auto;
        border: 1px solid #bcbec0;
        background: #ffffff;
        margin-bottom: 210px;
        position: relative;
        top: 30%;
        width: 80%;
        .confirm-heading p {
          padding: 11px;
          color: #ffffff;
          background: #bcbec0;
          font-size: 15px;
        }
        .confirmation-content {
          display: flex;

          .img {
            img {
              width: 42px;
              height: 42px;
            }
            padding: 20px;
            background: #00bfdf;
          }
          .alert {
            width: 100%;
            .text {
              margin: 20px 15px;
              p {
                letter-spacing: 0.05rem;
                line-height: 2.23;
                font-size: 12.55px;
                color: #6d6e71;
                white-space: normal;
              }
            }
            .group-button {
              justify-content: center;
              margin: 20px 0;
            }
          }
        }
      }
    }
    .form-main {
      max-width: 560px;
      margin: 55px auto;
      font-size: 11px;
      color: $color_7;
      padding-bottom: 400px;
      .error {
        color: $color_5;
        margin: 5px 0;
      }
      .form-input {
        color: $color_3;
      }
      .group-form {
        margin-top: 22px;
      }
      .custom-select {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        background: #ffffff url(../images/Asset10@2x.png) right 0.75rem
          center/10px 10px no-repeat;
        &:focus {
          outline: none;
        }
      }
      input,
      select {
        padding: 9.5px;
        border: 1px solid $color_3;
        border-radius: 4px;
      }
      .system {
        margin-top: 30px;
        span {
          font-size: 11px;
          color: $color_7;
        }
        .switch {
          position: relative;
          display: inline-block;
          width: 32px;
          height: 17px;
          margin: 10px;
          input {
            opacity: 0;
            width: 0;
            height: 0;
          }
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $color_2;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          &:before {
            position: absolute;
            content: '';
            height: 15px;
            width: 15px;
            left: -1px;
            bottom: -1px;
            background-color: $color_2;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
        }
        input:checked + .slider {
          background-color: $color_1;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px $color_1;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(15px);
          -ms-transform: translateX(15px);
          transform: translateX(15px);
        }
        .slider.round {
          border-radius: 34px;
          border: 1px solid $color_3;
        }
        .slider.round:before {
          border-radius: 50%;
          border: 1px solid $color_3;
        }
      }
    }
  }
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 13px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  bottom: -118px;
  right: -11px;
  font-size: 13px;
}

.dropdown-content a {
  float: none;
  color: $color_3;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.nav-item:hover .dropdown-content {
  display: block;
}
@media screen and (max-width: 1024px) {
  .program-main {
    margin-left: 55px;
    .header-wrapper {
      flex-direction: row;
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .program-content {
    .program-form .form-main {
      padding: 15px;
    }
    .content-wrapper {
      flex-direction: column;
      .program-management {
        flex-direction: row;
        min-width: 375px;
        .tab-content {
          min-width: 704px;
        }
      }
    }
    .program-form {
      min-width: 375px;
      margin-left: 0px;
      border: 1px solid #939598;
    }
  }
}
.sidebar-backdrop-p {
  display: none;
}
@media screen and (max-width: 969px) {
  .sidebar-backdrop-p {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1029;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    transition: 0.3s;
    opacity: 0.5;
    &.show {
      display: block;
    }
  }
  .sidebar_register {
    &.sidebar-Collapse {
      width: 50%;
      z-index: 10;
    }
  }
  .program-main {
    margin-left: 55px;
    &.main-slide {
      margin-left: 0px;
      width: calc(100%);
    }
    // .header-wrapper {
    //   flex-direction: column;
    //   .nav-right {
    //     margin: 0;
    //     justify-content: center;
    //   }
    // }
    .nav-left {
      flex-direction: row;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .program-content {
    .program-form .form-main {
      padding: 15px;
    }
    .content-wrapper {
      flex-direction: column;
      flex-wrap: wrap;
      .program-management {
        flex-direction: row;
        min-width: 375px;
        .tab-content {
          min-width: 0;
          width: 100%;
        }
      }
    }
    .program-form {
      min-width: 375px;
      margin-left: 0px;
      border: 1px solid #939598;
    }
  }
}
@media screen and (max-width: 768px) {
  .sidebar_register {
    &.sidebar-Collapse {
      width: 50%;
      z-index: 1030;
    }
  }
  .program-main {
    margin-left: 55px;
    &.main-slide {
      margin-left: 0px;
      width: calc(100%);
    }
    // .header-wrapper {
    //   flex-direction: column;
    //   .nav-right {
    //     margin: 0;
    //     justify-content: center;
    //   }
    // }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .program-content {
    .program-form .form-main {
      padding: 15px;
    }
    .content-wrapper {
      flex-direction: column;
      .program-management {
        flex-direction: row;
        min-width: 375px;
      }
    }
    .program-form {
      min-width: 375px;
      margin-left: 0px;
      border: 1px solid #939598;
    }
  }
}
.btn-collapse {
  display: none;
}
.dropdown-user {
  display: none;
  position: absolute;
  background-color: $color_2;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  font-size: 13px;
}
@media screen and (max-width: 425px) {
  .admin {
    .sidebar_register {
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      &.sidebar-Collapse {
        margin-left: 0px;
      }
    }
  }
  .btn-collapse {
    display: block;
    color: $color_1;
    padding: 0;
  }
  .program-main {
    margin-left: 0;
    width: calc(100%);
    &.main-slide {
      margin-left: 0;
      width: calc(100%);
      position: absolute;
      .program-form {
        min-width: 375px;
      }
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
    .header-wrapper {
      .logo-mb {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        svg {
          display: block;
          width: 100px;
          margin: auto;
        }
        img {
          width: 40px;
        }
        .show .dropdown-user {
          display: block;
        }
        .dropdown-user {
          .dropdown-header {
            padding: 8px 20px;
            background-color: $color_15;
          }
          a {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0.8rem 1.6rem;
            white-space: nowrap;
            color: $color_3;
            text-decoration: none;
            img {
              width: 1rem;
              height: 1rem;
              margin-right: 10px;
            }
          }
        }
      }

      .nav-right {
        .nav-item {
          display: none;
        }
      }
    }
  }
  .program-content {
    .program-form .form-main {
      padding: 15px;
    }
    .content-wrapper {
      flex-direction: column;
      flex-wrap: wrap;
      white-space: normal;
      .program-management {
        flex-direction: column;
        min-width: 375px;
      }
    }
    .program-form {
      min-width: 375px;
      margin-left: 0px;
      border: 1px solid #939598;
    }
  }
}

.mobile-menu {
  width: 100%;
  height: 600px;
  // margin-left: 54px;
  overflow: auto;
  .program-main {
    .header-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-left: 145px !important;
      overflow: auto;
    }
    .mobile-header-narbar {
      margin-left: 145px !important;
      .nav-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow: auto;
        gap: 20px;
        .nav-item {
          border-radius: 4px;
          padding: 0;
        }
      }
    }
    .wrap-list-program-menu {
      margin-left: 145px !important;
      max-height: 400px;
      overflow: auto;
    }
    // &.collapse {
    //   .header-wrapper {
    //     margin-left: 0 !important;
    //   }
    //   .mobile-header-narbar {
    //     margin-left: 0 !important;
    //   }
    //   .wrap-list-program-menu {
    //     margin-left: 0 !important;
    //   }
    // }
  }
}
