.member_register-form {
  .member_tab-pane {
    &.bond-balance {
      .registration-content {
        .table-wrapper {
          table {
            th:nth-child(2),
            td:nth-child(2) {
              text-align: left;
            }
            td:nth-child(6) {
              text-align: right;
            }
            .row-two td {
              background: $color_2;
            }
            .row-sum td {
              background: $color_19;
            }
          }
        }
      }
    }
    display: none;
    .group-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 20px;
    }
    .pagination {
      display: flex;
      list-style: none;
      justify-content: flex-end;
      border-radius: 3px;
      .page-item {
        &.active {
          .page-link {
            z-index: 2;
            background: $color_2;
            border-color: $color_1;
          }
        }
        .page-link {
          position: relative;
          display: flex;
          padding: 5px 8px;
          margin-right: 10px;
          line-height: 1.25;
          color: $color_1;
          border: 1px solid transparent;
          border-radius: 50px;
          text-decoration: none;
          font-size: 13px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .prev,
      .next {
        &.disabled {
          background: none;
        }
      }
      .prev {
        .page-link {
          display: flex;
          align-items: center;
          border: 1px solid $color_1;
          img {
            width: 13px;
            margin-right: 10px;
          }
        }
      }
      .next {
        .page-link {
          border: 1px solid $color_1;
          img {
            width: 13px;
            margin-right: 10px;
          }
        }
      }
    }
    .custom-select {
      position: relative;
      min-width: 46px;
      padding: 4px;
      cursor: pointer;
      background: #ffffff url(../images/Asset10@2x.png) right 0.5rem center/10px
        10px no-repeat;
      &:focus {
        outline: none;
      }
    }
    &.active {
      display: block;
    }
    .heading {
      font-size: 10px;
      color: $color_3;
      margin-bottom: 10px;
    }
    .label {
      font-size: 15px;
      color: $color_7;
      img {
        width: 15px;
      }
    }
    .code_registration {
      display: flex;
      &-content {
        width: 100%;
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 500px;
        border: 1px solid $color_3;
        .group-bottom {
          display: flex;
          justify-content: space-between;
          margin: 20px;
        }
        .pagination {
          display: flex;
          list-style: none;
          justify-content: flex-end;
          border-radius: 3px;
          .prev {
            .page-link {
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            }
          }
          .next {
            .page-link {
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
            }
          }
          .page-item {
            &.active {
              .page-link {
                z-index: 2;
                background: $color_18;
              }
            }
            .page-link {
              position: relative;
              display: block;
              padding: 5px 12px;
              margin-left: -1px;
              line-height: 1.25;
              color: $color_7;
              background-color: $color_16;
              border: 1px solid $color_3;
              text-decoration: none;
              font-size: 13px;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
        .custom-select {
          min-width: 46px;
          padding: 4px;
          cursor: pointer;
          background: #ffffff url(../images/Asset10@2x.png) right 0.5rem
            center/10px 10px no-repeat;
          &:focus {
            outline: none;
          }
        }
        .addNew {
          max-width: 518px;
          margin: 0 auto;
          border: 1px solid #bcbec0;
          background: #ffffff;
          margin-bottom: 210px;
          position: relative;
          top: 30%;
          width: 80%;
          padding: 20px;
          .addNew-heading {
            display: flex;
            justify-content: center;
            font-size: 25px;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 30px;
          }
          .group-btn {
            display: flex;
            justify-content: flex-end;
            .btn {
              margin: 0 30px;
              border: 1px solid $color_3;
              color: $color_7;
              border-radius: 3px;
              &:hover {
                opacity: 0.7;
              }
            }
            .btn-save {
              background: $color_16;
            }
            .btn-cancel {
              background: $color_14;
            }
          }
          .form {
            max-width: 412px;
            margin: 0 auto;
            .form-input {
              padding: 5px;
            }
            .form-code,
            .form-codeName,
            .form-note {
              margin-bottom: 25px;
            }
            .switch {
              position: relative;
              display: inline-block;
              width: 32px;
              height: 17px;
              margin-top: 10px;
              input {
                opacity: 0;
                width: 0;
                height: 0;
              }
            }
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: $color_2;
              -webkit-transition: 0.4s;
              transition: 0.4s;
              &:before {
                position: absolute;
                content: '';
                height: 15px;
                width: 15px;
                left: -1px;
                bottom: -1px;
                background-color: $color_2;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }
            }
            input:checked + .slider {
              background-color: $color_13;
            }

            input:focus + .slider {
              box-shadow: 0 0 1px $color_1;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(15px);
              -ms-transform: translateX(15px);
              transform: translateX(15px);
            }
            .slider.round {
              border-radius: 34px;
              border: 1px solid $color_3;
            }
            .slider.round:before {
              border-radius: 50%;
              border: 1px solid $color_3;
            }
          }
        }
        .group-form {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 20px;
          .result {
            margin-left: 30px;
            color: $color_7;
            font-size: 13px;
          }
          .group-button {
            margin-top: 0;
            .btn-delete,
            .btn-create {
              border: 1px solid $color_3;
              border-radius: 3px;
              margin: 0 10px;
              color: $color_7;
              background: $color_16;
              &:hover {
                opacity: 0.7;
              }
            }
            .btn-delete {
              background: $color_18;
            }
          }
        }
        .input-group {
          margin-top: 40px;
          margin-bottom: 100px;
          margin-left: 15px;
          max-width: 300px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .form-control {
            padding: 5px;
            margin: 0;
          }
          .btn-search {
            padding: 5px;
            color: $color_7;
            margin-left: -1px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .code-navbar {
        min-width: 250px;
        border: 1px solid $color_3;
        margin-top: 10px;
        margin-bottom: 10px;
        .menu-list {
          list-style: none;
          padding: 0 30px;
          .menu-item {
            margin-top: 30px;
            > a {
              text-decoration: none;
              font-size: 14px;
              color: $color_7;
              display: flex;
              align-items: center;
              img {
                width: 10px;
                margin-right: 10px;
              }
            }
          }
          .submenu {
            list-style: none;
            padding: 0 25px;
            display: block;
            &.showMenu {
              display: none;
            }
            li {
              margin: 10px 0;
              a {
                text-decoration: none;
                font-size: 14px;
                color: $color_7;
                padding: 2px;
                border: 1px solid transparent;
                &.active {
                  background: orange;
                  border: 1px solid $color_3;
                  color: $color_2;
                }
              }
            }
          }
        }
      }
    }
  }
  .form-main {
    .btn-center {
      justify-content: center;
      margin-bottom: 65px;
    }
    .btn-primary {
      border-radius: 50px;
      margin: 0 0 5px 19px;
      border: 1px solid $color_1;
      transition: all 0.1s ease-in;
      &:hover {
        border-color: $color_17;
        color: $color_7;
        transition: all 0.1s ease-in;
        .cls-3 {
          fill: $color_7;
          transition: all 0.1s ease-in;
        }
      }
      &:disabled,
      &[disabled] {
        background: $color_3;
        color: $color_2;
        border: 1px solid transparent;
        cursor: not-allowed;
        span {
          color: $color_2;
        }
        .cls-3 {
          fill: $color_2;
        }
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        transition: all 0.1s ease-in;
      }
    }
    .input_Opera-time {
      min-height: 76px;
      width: 100%;
      resize: none;
    }
  }
}
.admin {
  position: relative;
}
.sidebar_register {
  background: $color_1;
  width: 55px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  .sidebar-brand {
    padding: 6px;
  }
  &.sidebar-Collapse {
    width: 146px;
  }
  &-menu {
    svg {
      width: 20px;
      height: 20px;
      path {
        &.cls-2 {
          fill: $color_1;
        }
      }
    }
    .tab-link {
      padding: 15px;
      border-top: 1px solid $color_17;
      border-bottom: unset;
      justify-content: center;
      &.active {
        background: $color_6;
        border-right: none;
      }
    }
  }
  .tab-search {
    background: $color_6;
  }
}
.sub-menu {
  a {
    &:first-of-type {
      .tab-link {
        border-top: unset !important;
      }
    }
  }
}

.member_register-main {
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  margin-left: 55px;
  width: calc(100% - 55px);
  position: absolute;
  top: 0;
  header {
    width: 100%;
    position: sticky;
    top: 0;
    background: $color_2;
    z-index: 1029;
    .logo-mb {
      display: none;
    }
  }
  &.main-slide {
    margin-left: 146px;
    width: calc(100% - 146px);
    position: absolute;
    .program-form {
      min-width: 633px;
      transition: all 0.5s ease-in-out;
    }
  }
  .nav-left {
    padding: 15px 0;
    list-style: none;
    display: flex;
    .nav-item:nth-child(1) {
      .nav-link {
        margin-right: 4px;
        img {
          width: 20px;
          height: 15px;
        }
      }
    }
    .nav-item:nth-child(2) {
      .nav-link {
        margin-left: 4px;
        img {
          width: 20px;
          height: 15px;
        }
      }
    }
    .nav-item:nth-child(3) {
      .nav-link {
        color: $color_14;
      }
    }
    .nav-item:nth-child(4) {
      .nav-link {
        color: $color_13;
      }
    }
    .nav-item:nth-child(5) {
      .nav-link {
        color: $color_12;
      }
    }
    .nav-item:nth-child(6) {
      .nav-link {
        color: $color_11;
      }
    }
    .nav-item:nth-child(7) {
      .nav-link {
        color: $color_10;
      }
    }
    .nav-item:nth-child(8) {
      .nav-link {
        color: $color_9;
      }
    }
    .nav-item:nth-child(9) {
      .nav-link {
        color: $color_8;
      }
    }
    .nav-item:nth-child(10) {
      .nav-link {
        color: $color_7;
      }
    }
    .nav-item:nth-child(11) {
      .nav-link {
        color: $color_3;
      }
    }
    .nav-link {
      text-decoration: none;
      margin: 0 15px;
      font-size: 13px;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
      }
    }
    .nav-item {
      display: flex;
      align-items: center;
    }
  }
  .header {
    &-wrapper {
      display: flex;
      .nav-right {
        list-style: none;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 12px;
        .nav-item {
          padding: 4px 0;
          &:nth-child(1) {
            img {
              width: 30px;
              height: 30px;
            }
          }
          &:nth-child(2) {
            img {
              width: 40px;
              height: 40px;
            }
          }
          .nav-link {
            text-decoration: none;
            margin: 0 8px;
          }
        }
        .nav-item {
          position: relative;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          .nav-link {
            white-space: nowrap;
            color: $color_3;
            transition: all 0.5s ease-in;
            &:hover {
              transform: scale(1.1);
              -webkit-transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}
.member_register-content {
  .content-wrapper {
    display: flex;
  }
  .member_register-management {
    background: $color_6;
    .navbar-wrapper {
      min-width: 265px;
    }
    .member_register-title {
      display: flex;
      align-items: center;
      margin-left: 16px;
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 13px;
      color: $color_7;
      svg {
        width: 15px;
        margin-right: 6px;
      }
    }
    .nav-main {
      margin-left: 22px;
      list-style: none;
      display: flex;
      flex-direction: column;
      .nav-item {
        display: inline-block;
        padding: 10px;
        border: 1px solid transparent;
        .text {
          margin-right: 10px;
        }
        &.active {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border: 1px solid $color_3;
          border-right: none;
          background: $color_2;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: $color_2;
            right: -1px;
            top: 0;
          }
        }
      }
      .nav-link {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 11px;
        color: $color_7;
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
        .number {
          margin-left: auto;
          white-space: nowrap;
        }
      }
    }
    .tab-content {
      background: $color_2;
      min-width: 300px;
      border: 1px solid $color_3;
    }
  }
}
.member_register-form {
  width: 100%;
  border: 1px solid $color_3;
  .group-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 15px;

    .btn-primary {
      border-radius: 50px;
      margin: 0 5px;
      border: 1px solid $color_1;
      transition: all 0.1s ease-in;
      &:hover {
        border-color: $color_17;
        color: $color_7;
        transition: all 0.1s ease-in;
        .cls-3 {
          fill: $color_7;
          transition: all 0.1s ease-in;
        }
      }
      &:disabled,
      &[disabled] {
        background: $color_3;
        color: $color_2;
        border: 1px solid transparent;
        cursor: not-allowed;
        .cls-3 {
          fill: $color_2;
        }
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        transition: all 0.3s ease-in;
      }
    }
  }
  .member_register-tabs {
    position: relative;
    min-height: 200px;
    clear: both;
    margin: 30px 40px;
    background: white;
    padding-bottom: 88px;
    ul.tabs {
      margin: 0px;
      padding: 0px;
      list-style: none;
      flex-direction: row;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    ul.tabs li {
      font-size: 13px;
      background: $color_6;
      color: $color_7;
      display: inline-block;
      padding: 10px;
      cursor: pointer;
      border: 1px solid $color_16;
      text-align: center;
      margin-left: -1px;
    }

    ul.tabs li.current {
      background: $color_18;
      color: $color_7;
      border: 1px solid $color_16;
    }

    .registration-content {
      padding: 0px;
      margin-top: 20px;

      .text-member {
        font-size: 11px;
        color: $color_7;
        margin: 22px 0 10px 0;
      }
      .modal-bondBalance {
        &::-webkit-scrollbar {
          display: none;
        }
        .confirmation {
          top: 10%;
        }
        .confirmation-content {
          .alert {
            width: 100%;
            display: grid;
            padding: 20px;
            .pickDate-wrapper {
              display: flex;
              position: relative;
              .dropright-content {
                padding: 10px;
                list-style: none;
                display: none;
                position: absolute;
                border: 1px solid $color_3;
                background: $color_2;
                border-radius: 4px;
                width: 169px;
                z-index: 1031;
                top: 10px;
                right: 0;
                font-size: 11px;
                &.show {
                  display: block;
                }
                .dropright-item {
                  padding: 5px 0;
                }
                .dropright-link {
                  text-decoration: none;
                  color: $color_7;
                  &:hover {
                    color: $color_1;
                  }
                }
              }
            }
            .select-approve {
              margin: 0 auto;
              .label {
                font-size: 11px;
              }
            }
            .search-input {
              margin: 15px 0;
              justify-content: center;
              max-width: none;
              .btn {
                margin: 0;
              }
            }
            .table-wrapper {
              table {
                td:last-child {
                  text-align: right;
                }
              }
            }
            .group-button {
              margin-top: 30px;
              justify-content: center;
              .btn {
                margin: 0;
              }
            }
            .form-fromDay {
              .label {
                font-size: 11px;
                color: $color_7;
              }
              .input-wrapper {
                max-width: 162px;
                margin-top: 10px;
                display: flex;
                position: relative;
                .form-input {
                  margin: 0;
                  padding: 10px 15px;
                  border-radius: 4px;
                  border: 1px solid $color_3;
                  color: $color_3;
                }
                .js-calendar {
                  position: absolute;
                  right: 2px;
                  top: 4px;
                  margin: 0;
                  background: none;
                  border: none;
                  svg {
                    width: 18px;
                  }
                  &:hover {
                    svg {
                      path {
                        fill: $color_1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .modal-bank-2 {
        z-index: 1033;
        .confirmation-content {
          .alert {
            display: grid;
          }
          .table-wrapper {
            margin: 20px;
          }
        }
      }
      .modal-newDues {
        .confirmation-main {
          padding: 20px 35px;
          .radio_check-wrapper {
            margin: 0;
          }
          .btn-center {
            justify-content: center;
          }
          .group-form {
            .form-Note {
              width: 100%;
              .input-wrapper {
                width: 100%;
                textarea {
                  width: 100%;
                  resize: none;
                }
              }
            }
            .input-wrapper {
              margin-top: 10px;
              display: flex;
              flex-direction: column;
              position: relative;
              .form-input {
                margin-top: 0;
                border-radius: 4px;
                border: 1px solid $color_3;
                color: $color_3;
                padding: 10px;
              }
              a {
                position: absolute;
                right: 0;
                top: 3px;
                margin-bottom: 10px;
                svg {
                  width: 18px;
                }
                &:hover {
                  svg {
                    .cls-6 {
                      fill: $color_1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .radio_check-wrapper {
        display: flex;
        align-items: center;
        margin: 25px 35px;
        .label-check {
          display: block;
          position: relative;
          padding-left: 15px;
          cursor: pointer;
          font-size: 11px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: $color_7;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 12px;
            width: 12px;
            border: 1px solid $color_16;
            border-radius: 50%;
          }
          &:hover input ~ .checkmark {
            background-color: $color_16;
          }
          input:checked ~ .checkmark {
            background-color: $color_16;
          }
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }
          input:checked ~ .checkmark:after {
            display: block;
          }
          .checkmark:after {
            top: 2px;
            left: 2px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $color_2;
          }
        }
        .label-check {
          &:nth-child(2) {
            margin-left: 30px;
          }
        }
      }
      .modal-editDues {
        .confirmation {
          max-width: 780px;
        }
        .confirmation-main {
          .search-wrapper {
            margin: 4px 0;
            margin-left: auto;
          }
          .js-deleteShow {
            padding: 0;
            &:disabled,
            &[disabled] {
              color: $color_7;
              border: 1px solid transparent;
              cursor: not-allowed;
            }
          }
          .btn-primary {
            border-radius: 50px;
            border: 1px solid $color_1;
            transition: all 0.1s ease-in;
            // span {
            //     color: $color_1;
            // };
            svg {
              width: 15px;
              margin-right: 7px;
            }
            &:hover {
              border-color: $color_17;
              color: $color_7;
              transition: all 0.1s ease-in;
              .cls-3 {
                fill: $color_7;
                transition: all 0.1s ease-in;
              }
            }
            &:disabled,
            &[disabled] {
              background: $color_3;
              color: $color_2;
              border: 1px solid transparent;
              cursor: not-allowed;
              .cls-3 {
                fill: $color_2;
              }
            }
          }
          .label {
            margin: 0 35px;
          }
          .btn-center {
            justify-content: center;
            margin: 50px 0;
          }
          .form-date {
            margin: 15px 35px;
            display: flex;
            justify-content: start;
            .tidle {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 10px;
              span {
                font-size: 20px;
              }
            }
            .input-wrapper {
              display: flex;
              align-items: center;
              .form-input {
                margin-top: 0;
                border-radius: 4px;
                border: 1px solid $color_3;
                color: $color_3;
                padding: 10px;
              }
              a {
                margin-left: -35px;
                img {
                  width: 18px;
                }
              }
            }
          }
          .table-wrapper {
            margin: 25px 35px;

            table {
              tr th:nth-child(2) {
                text-align: center;
              }
            }
          }
        }
      }
      .form {
        .form-input,
        .custom-select {
          margin-bottom: 10px;
        }
        .form-deposit {
          select {
            width: 100%;
            border-radius: 4px;
            border: 1px solid $color_3;
            margin: 10px 0;
            color: $color_7;
          }
        }
        .form-amount,
        .form-date {
          width: 25%;
        }
        .error {
          color: $color_5;
          font-size: 15px;
        }
        margin-top: 40px;
        .modal-new {
          .alert {
            display: grid;
            .table-wrapper {
              margin: 0 10px !important;
            }
          }
        }
        .modal-bank {
          .alert {
            display: grid;
            .table-wrapper {
              margin: 40px 20px 20px 20px;
            }
            .group-button {
              margin-top: 10px;
            }
          }
        }
        .modal-document {
          .alert {
            width: 100%;
            .group-button {
              margin-top: 20px;
            }
            .wrap-custom-file {
              margin: 50px 0 0 0;
              border: none;
              height: auto;
              width: 100%;
              position: initial;
              label {
                position: initial;
                display: flex;
                flex-direction: column;
                align-items: center;
                span {
                  font-size: 12.55px;
                }
              }
              img {
                width: 200px;
              }
            }
          }
        }
        .modal-postCode {
          overflow: hidden;
          .confirmation {
            top: 15%;
          }
          .alert {
            width: 100%;
            p {
              font-size: 15px;
              color: $color_3;
            }
            .address-input {
              align-items: center;
              flex-wrap: wrap;
              padding: 0 15px;
              input {
                max-width: 95px;
                margin-top: 0;
              }
              svg {
                position: initial;
                margin: 0 10px;
              }
              span {
                font-size: 12.55px;
                color: $color_7;
              }
            }
            .group-button {
              margin-top: 10px;
              justify-content: center;
            }
            .result {
              padding: 15px;
              .label-check {
                display: block;
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;
                cursor: pointer;
                font-size: 12.55px;
                color: $color_7;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                }
                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 15px;
                  width: 15px;
                  border: 1px solid $color_7;
                }
                .checkmark:after {
                  content: '';
                  position: absolute;
                  display: none;
                }
                input:checked ~ .checkmark:after {
                  display: block;
                }
                .checkmark:after {
                  left: 2px;
                  bottom: 0px;
                  width: 10px;
                  height: 12px;
                  background: url(../images/icon_checked2.svg) no-repeat center
                    center;
                  background-size: 100% 100%;
                }
              }
              p {
                color: $color_3;
                font-size: 15px;
              }
              &-list {
                height: 175px;
                overflow-y: auto;
                padding: 10px;
                scrollbar-width: auto;
                scrollbar-color: #d1d3d4 #ffffff;
                &::-webkit-scrollbar {
                  width: 12px;
                  margin-top: 20px;
                  padding: 0 2px;
                }

                &::-webkit-scrollbar-track {
                  background: #ffffff;
                  margin-top: 10px;
                  border: 1px solid #d1d3d4;
                  border-radius: 4px;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #d1d3d4;
                  border-radius: 10px;
                  margin: 0px 2px;
                  width: 8px;
                }
                .result-item {
                  font-size: 12.55px;
                  color: $color_7;
                }
              }
            }
          }
        }
        .list-store {
          margin-top: 35px;
        }
        .js-addNew {
          margin-top: 20px;
          margin-bottom: 15px;
          color: $color_7;
          svg {
            width: 15px;
            margin-right: 10px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        .table-wrapper {
          .modal {
            .confirmation {
              max-width: 650px;
              .group-button {
                margin-top: 40px;
              }
              .table-wrapper {
                margin: 25px;
                .label-check {
                  padding-left: 15px;
                }
                th:nth-child(2),
                tr td:nth-child(2) {
                  text-align: center;
                }
              }
            }
          }
        }
        .form-Note {
          display: flex;
          flex-direction: column;
          textarea {
            resize: none;
            margin-top: 10px;
          }
        }
        .form-regisDocs {
          margin-top: 25px;
          .modal-preview {
            overflow: hidden;
            .confirmation {
              top: 10%;
            }
            .confirmation-main {
              .preview-content {
                margin: 20px auto;
                border: 1px solid $color_1;
                max-width: 550px;
                height: 300px;
              }
            }
            .group-button {
              margin: 15px;
              justify-content: center;
            }
          }
          .drag-area {
            border: 1px solid $color_3;
            height: 300px;
            width: 100%;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 10px 0;
          }
          .drag-area.active {
            border: 1px solid $color_3;
          }
          .drag-area .icon {
            width: 220px;
            img {
              width: 100%;
            }
          }
          .drag-area p {
            font-size: 12.55px;
            color: $color_7;
          }
          .drag-area span {
            font-size: 12.55px;
            color: $color_7;
            margin: 10px;
          }
          .drag-area a {
            font-size: 12.55px;
            border: none;
            outline: none;
            background: $color_2;
            color: $color_14;
            cursor: pointer;
            text-decoration: underline;
          }
          .form-row {
            position: relative;
            margin-bottom: 20px;
          }
          .file-up-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 18px;
            .file-uploaded {
              &:not(:first-child) {
                margin-left: 10px;
              }
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $color_3;
              border-radius: 4px;
              padding: 9.5px 15px;
              span {
                color: $color_7;
                font-size: 11px;
              }
              .btn {
                svg {
                  position: initial;
                  width: 11px;
                }
              }
            }
          }
          svg {
            width: 15px;
          }
          .label-upload {
            display: inline-block;
            background-color: $color_19;
            border: 1px solid $color_3;
            color: $color_3;
            padding: 15px 15px 15px 50px;
            border-radius: 4px;
            cursor: pointer;
            width: 100%;
          }
          .doc-notice {
            font-size: 11px;
            margin: 20px;
            color: $color_7;
          }
        }

        .label {
          font-size: 15px;
        }
        .btn-center {
          justify-content: center;
        }
        .form-mb {
          margin-bottom: 15px;
        }
        .form-input {
          border-radius: 4px;
          border: 1px solid $color_3;
          color: $color_7;
        }
        .form-row {
          display: flex;
          margin-top: 10px;
          input {
            margin-top: 0;
            &:disabled {
              background: $color_6;
            }
          }
        }
        .form-mobile,
        .form-phone,
        .form-bank,
        .form-accountNum,
        .form-despositor {
          width: 48%;
          .select-phone {
            margin-right: 25px;
            .custom-select {
              border: 1px solid $color_3;
              border-radius: 4px;
              color: $color_7;
            }
          }
          .selectphone {
            position: relative;
          }
          a {
            position: absolute;
            top: 8px;
            right: 10px;
          }
          svg {
            width: 20px;
          }
        }
        .form-bank,
        .form-despositor {
          input {
            padding-right: 45px;
          }
        }
        .address-input {
          input {
            padding-left: 45px;
          }
        }
        .form-address {
          width: 15%;
        }
        .form-street {
          width: 81%;
        }
        .address-input {
          display: flex;
          position: relative;
          svg {
            width: 20px;
            position: absolute;
            top: 28%;
            left: 15px;
          }
        }
      }
      .form-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-top {
          width: 70%;
          white-space: normal;
        }
      }
      .wrap-custom-file {
        position: relative;
        display: inline-block;
        width: 247px;
        height: 247px;
        text-align: center;
        border: 1px solid $color_3;
        border-radius: 4px;
        margin-top: 20px;
        input[type='file'] {
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 2px;
          overflow: hidden;
          opacity: 0;
        }
        label {
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          overflow: hidden;
          cursor: pointer;
          background-color: #fff;
          border-radius: 4px;
          display: flex;
          align-items: end;
          justify-content: center;
          -webkit-transition: -webkit-transform 0.4s;
          -moz-transition: -moz-transform 0.4s;
          -ms-transition: -ms-transform 0.4s;
          -o-transition: -o-transform 0.4s;
          transition: transform 0.4s;
          span {
            display: block;
            color: $color_3;
            font-size: 15px;
            -webkit-transition: color 0.4s;
            -moz-transition: color 0.4s;
            -ms-transition: color 0.4s;
            -o-transition: color 0.4s;
            transition: color 0.4s;
            margin-bottom: 30px;
          }
          &:hover {
            span {
              opacity: 0.7;
            }
          }
          &.file-ok {
            //Styles img background
            background-size: 100% 100%;
            background-position: center;
            span {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              color: #000;
              background-color: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }
      .group-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 25px;
        .select-approve {
          display: flex;
          align-items: center;
          .label {
            margin-right: 15px;
            color: $color_7;
          }
          .custom-select {
            min-width: 115px;
            color: $color_7;
            option {
              color: $color_7;
            }
          }
        }
      }
      .group-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .result {
          color: $color_7;
          font-size: 11px;
        }
      }
      .table-wrapper,
      .group-bottom {
        margin: 20px 0;
        .select-approve {
          .custom-select {
            font-size: 11px;
            color: $color_7;
          }
          .label {
            font-size: 11px;
          }
        }
      }
      .search-input {
        display: flex;
        align-items: center;
        max-width: 450px;
        .form-input {
          border-radius: 50px;
          border: 1px solid $color_3;
          margin: 0;
          padding: 7px 10px;
          color: $color_16;
          &::placeholder {
            color: $color_16;
          }
        }
        .btn-primary {
          border-radius: 50px;
          margin: 0 5px;
          border: 1px solid $color_1;
          transition: all 0.1s ease-in;
          margin-right: 40px;
          &:hover {
            border-color: $color_17;
            color: $color_7;
            transition: all 0.1s ease-in;
            .cls-3 {
              fill: $color_7;
              transition: all 0.1s ease-in;
            }
          }
          &:disabled,
          &[disabled] {
            background: $color_3;
            color: $color_2;
            border: 1px solid transparent;
            cursor: not-allowed;
            .cls-3 {
              fill: $color_2;
            }
          }
          svg {
            width: 16px;
            height: 16px;
            margin-right: 7px;
          }
        }
      }
      .button-slide {
        margin: 170px 0 80px 0;
        .btn-primary {
          margin: 0 48px;
        }
      }
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    position: fixed;
    z-index: 1030;
    left: 0;
    top: 0;
  }
  .confirmation {
    max-width: 650px;
    margin: 0 auto;
    border: 1px solid $color_16;
    background: $color_2;
    margin-bottom: 210px;
    position: relative;
    top: 30%;
    width: 80%;
    .confirm-heading {
      p {
        padding: 11px;
        color: $color_2;
        background: $color_16;
        font-size: 15px;
      }
    }
    &-content {
      display: flex;
      .img {
        padding: 20px;
        background: $color_1;
        img {
          width: 42px;
          height: 42px;
        }
      }
      .alert {
        .text {
          margin: 20px 15px;
        }
        p {
          letter-spacing: 0.05rem;
          line-height: 2.23;
          font-size: 12.55px;
          color: $color_7;
          white-space: normal;
        }
        .group-button {
          margin-right: 0;
          margin-bottom: 30px;
        }
        button {
          margin-right: 50px;
        }
      }
    }
  }
  .form-main {
    max-width: 560px;
    margin: 25px;
    font-size: 11px;
    color: $color_7;
    .group-form {
      margin-top: 22px;
      width: 43%;
      white-space: normal;
    }
    .form-OperatingTime {
      width: 100%;
    }
    .group-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .address-wrapper {
      justify-content: start;
      align-items: flex-end;
    }
    .form-phone {
      margin-top: 22px;
      .select-phone {
        width: 14.5%;
      }
      .form-row {
        display: flex;
      }
      .selectphone {
        margin-top: 0;
        margin-left: 10px;
      }
    }
    .group-login {
      display: flex;
      align-items: flex-end;
      button img {
        width: 15px;
      }
      .btn-primary {
        border-radius: 50px;
        margin: 5px 18px;
        border: 1px solid $color_1;
        transition: all 0.1s ease-in;
        &:hover {
          border-color: $color_17;
          color: $color_7;
          transition: all 0.1s ease-in;
          .cls-3 {
            fill: $color_7;
            transition: all 0.1s ease-in;
          }
        }
        &:disabled,
        &[disabled] {
          background: $color_3;
          color: $color_2;
          border: 1px solid transparent;
          cursor: not-allowed;
          .cls-3 {
            fill: $color_2;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          margin-right: 7px;
          transition: all 0.1s ease-in;
        }
      }
    }
    .group-email {
      display: flex;
      align-items: center;
      .group-form:nth-child(2) {
        margin-left: 65px;
        .approve {
          margin-left: 15px;
        }
      }
      span {
        font-size: 11px;
        color: $color_7;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 17px;
        margin: 15px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color_2;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: '';
          height: 15px;
          width: 15px;
          left: -1px;
          bottom: -1px;
          background-color: $color_2;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }
      input:checked + .slider {
        background-color: $color_1;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px $color_1;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }
      .slider.round {
        border-radius: 34px;
        border: 1px solid $color_3;
      }
      .slider.round:before {
        border-radius: 50%;
        border: 1px solid $color_3;
      }
    }
    .custom-select {
      width: 100%;
      margin-top: 10px;
      cursor: pointer;
      background: #ffffff url(../images/Asset10@2x.png) right 0.75rem
        center/10px 10px no-repeat;
      color: $color_7;
      &:focus {
        outline: none;
      }
    }
    input,
    select,
    textarea {
      padding: 9.5px;
      border: 1px solid $color_3;
      border-radius: 4px;
      margin-top: 10px;
      &:focus {
        outline: none;
      }
    }
    .system {
      margin-top: 30px;
      span {
        font-size: 11px;
        color: $color_7;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 17px;
        margin: 10px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color_2;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: '';
          height: 15px;
          width: 15px;
          left: -1px;
          bottom: -1px;
          background-color: $color_2;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }
      input:checked + .slider {
        background-color: $color_1;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px $color_1;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }
      .slider.round {
        border-radius: 34px;
        border: 1px solid $color_3;
      }
      .slider.round:before {
        border-radius: 50%;
        border: 1px solid $color_3;
      }
    }
  }
  .permission {
    position: relative;
    margin-top: 60px;
    span {
      background-color: $color_2;
      font-weight: normal;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.5em;
      border-top: 1px solid $color_16;
      z-index: -1;
    }
  }
  .form-heading {
    span {
      background-color: $color_2;
      font-weight: normal;
      font-size: 15px;
    }
  }
  .group-row {
    display: flex;
    .form-check:nth-child(2) {
      margin-left: 45px;
    }
  }
  .group-button {
    margin-top: 100px;
    justify-content: flex-end;
  }
  .group-btn-mb {
    margin-top: 0px;
    margin-right: 0;
  }
  .form-check {
    margin-top: 30px;
    .dropdown-common {
      // display: none;
      margin-left: 22px;
    }
    .check-submenu {
      display: flex;
      flex-direction: column;
      margin-left: 22px;
    }
    .label-check {
      display: inline-block;
      position: relative;
      cursor: pointer;
      font-size: 11px;
      user-select: none;
      padding-left: 23px;
      margin-bottom: 10px;
      img {
        margin-right: 10px;
        width: 10px;
      }
    }
    .label-check input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 13px;
      height: 13px;
      background: $color_2;
      border: 1px solid $color_16;
    }
    .label-check:hover .checkmark {
      background: #ccc;
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    .label-check .checkmark:after {
      top: 20%;
      left: 12%;
      width: 9px;
      height: 9px;
      background: url(../images/icon_checked.svg) no-repeat center center;
      background-size: 100% 100%;
    }
    .label-check input:checked ~ .checkmark {
      background: #2196f3;
      border: 1px solid transparent;
    }
    .label-check input:checked ~ .checkmark:after {
      display: block;
    }
  }
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 13px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  bottom: -118px;
  right: -11px;
  font-size: 13px;
}

.dropdown-content a {
  float: none;
  color: $color_3;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.nav-item:hover .dropdown-content {
  display: block;
}
@media screen and (max-width: 1024px) {
  .member_register-main {
    margin-left: 55px;
    .header-wrapper {
      flex-direction: row;
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .member_register-form .form-main {
    padding: 15px;
  }
  .content-wrapper {
    flex-direction: column;
    .member_register-management {
      flex-direction: row;
      .tab-content {
        min-width: 704px;
      }
      .nav-main {
        padding-bottom: 10px;
      }
    }
  }
  .member_register-form {
    margin-left: 0px;
    border: 1px solid $color_3;
  }
}
.sidebar-backdrop-p {
  display: none;
}
@media screen and (max-width: 969px) {
  .sidebar-backdrop-p {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1029;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    transition: 0.3s;
    opacity: 0.5;
    &.show {
      display: block;
    }
  }
  .sidebar_register {
    &.sidebar-Collapse {
      width: 50%;
      z-index: 10;
    }
  }
  .member_register-main {
    margin-left: 55px;
    &.main-slide {
      margin-left: 0px;
      width: calc(100%);
    }
    .header-wrapper {
      flex-direction: column;
      .nav-right {
        margin: 0;
        justify-content: center;
      }
    }
    .nav-left {
      flex-direction: row;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .member_register-form .form-main {
    padding: 15px;
  }
  .content-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    white-space: nowrap;
    .member_register-management {
      flex-direction: row;
      .tab-content {
        min-width: 650px;
      }
    }
  }
  .member_register-form {
    margin-left: 0px;
    border: 1px solid #939598;
  }
}
@media screen and (max-width: 812px) {
  #datepicker-div {
    top: 53px;
    left: 0;
  }
  .member_register-form {
    .member_register-tabs {
      .registration-content {
        .modal-bondBalance {
          .confirmation-content {
            .alert {
              .pickDate-wrapper .dropright-content {
                top: 53px;
                left: 194px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 605px) {
  .member_register-form {
    .member_register-tabs {
      .registration-content {
        .modal-bondBalance {
          .confirmation-content {
            .alert {
              .pickDate-wrapper .dropright-content {
                top: 308px;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 368px) {
  #datepicker-div {
    left: -57px;
  }
  .member_register-form {
    .member_register-tabs {
      .registration-content {
        .modal-bondBalance {
          .confirmation-content {
            .alert {
              .pickDate-wrapper .dropright-content {
                left: -57px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .sidebar_register {
    &.sidebar-Collapse {
      width: 50%;
      z-index: 1030;
    }
  }

  .member_register-main {
    margin-left: 55px;
    &.main-slide {
      margin-left: 0px;
      width: calc(100%);
    }
    .header-wrapper {
      flex-direction: column;
      .nav-right {
        margin: 0;
        justify-content: center;
      }
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      .nav-link {
        margin: 0 10px;
      }
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
  }
  .member_register-form .form-main {
    padding: 15px;
  }
  .content-wrapper {
    flex-direction: column;
    .member_register-management {
      flex-direction: row;
      .tab-content {
        min-width: 458px;
      }
    }
  }
  .member_register-form {
    margin-left: 0px;
    border: 1px solid #939598;
    .member_register-tabs {
      .registration-content {
        .form {
          .form-amount,
          .form-date {
            width: 100%;
          }
          .form-regisDocs {
            .modal-preview {
              .confirmation-main .preview-content {
                margin: 20px;
              }
            }
          }
        }
        .modal-editDues {
          .confirmation-main {
            .form-date {
              flex-direction: column;
            }
          }
        }
      }
    }
    .member_register-tabs .registration-content .form-mp {
      flex-direction: column;
      .form-mobile,
      .form-phone {
        width: 100%;
      }
    }
    .center-tabs {
      margin-left: 10px;
      margin-right: 10px;
    }
    .member_tab-pane {
      .code_registration {
        .code-navbar {
          margin: 10px;
        }
        &-content {
          margin: 10px;
          width: auto;
          margin-bottom: 40px;
          .input-group {
            margin: 10px;
          }
          .group-form .group-button {
            margin-right: 0;
          }
        }
      }
    }
    .member_tab-pane .code_registration-content .group-form .result {
      margin-left: 0px;
    }
  }
}
@media screen and (max-width: 644px) {
  .member_register-form {
    .member_register-tabs {
      .registration-content {
        .group-row .select-approve {
          margin-top: 15px;
        }
      }
    }
  }
}
.btn-collapse {
  display: none;
}
.dropdown-user {
  display: none;
  position: absolute;
  background-color: $color_2;
  min-width: 160px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  font-size: 13px;
}
.table-wrapper {
  margin: 20px;
  overflow-x: auto;
}
.registration-content table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  position: relative;
  .highlight td {
    background: $color_19;
  }
  tbody {
    tr:nth-child(2) {
      td {
        background: $color_19;
      }
    }
  }
  tr {
    border: 1px solid $color_7;
    padding: 0.35em;
    height: 32px;
    th {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }
      background: $color_19;
    }
    td {
      padding: 8px 5px;
      background: $color_2;
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }
    }
  }
  th {
    padding: 5px;
  }
  th,
  td {
    a {
      color: $color_7;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $color_1;
      }
    }
    text-align: left;
    border: 1px solid $color_7;
    color: $color_7;
    font-size: 13px;
    white-space: nowrap;
    .label-check {
      display: block;
      position: relative;
      padding-left: 15px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: -2px;
        left: 0;
        height: 15px;
        width: 15px;
        border: 1px solid $color_7;
      }
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark:after {
        left: 2px;
        bottom: 0px;
        width: 10px;
        height: 12px;
        background: url(../images/icon_checked2.svg) no-repeat center center;
        background-size: 100% 100%;
      }
    }
  }
}

@media screen and(max-width:739px) {
  .member_register-form {
    .member_tab-pane .code_registration {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 512px) {
  .member_register-form {
    .member_register-tabs {
      margin: 10px;
    }
    .confirmation-content {
      .alert button {
        margin-right: 10px;
      }
    }
    .code_registration {
      flex-direction: column;
    }
  }
  .admin {
    .sidebar_register {
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      &.sidebar-Collapse {
        margin-left: 0px;
      }
    }
  }
  .btn-collapse {
    display: block;
    color: $color_1;
    padding: 0;
  }
  .member_register-main {
    margin-left: 0;
    width: calc(100%);
    &.main-slide {
      margin-left: 0;
      width: calc(100%);
      position: absolute;
      .member_register-form {
        min-width: 375px;
      }
    }
    .nav-left {
      flex-direction: row;
      flex-wrap: wrap;
      .nav-item:nth-child(1) .nav-link {
        margin-left: 15px;
      }
      .nav-item {
        margin: 5px 0;
      }
    }
    .header-wrapper {
      .logo-mb {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        svg {
          display: block;
          width: 100px;
          margin: auto;
        }
        img {
          width: 40px;
        }
        .show .dropdown-user {
          display: block;
        }
        .dropdown-user {
          .dropdown-header {
            padding: 8px 20px;
            background-color: $color_15;
          }
          a {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0.8rem 1.6rem;
            white-space: nowrap;
            color: $color_3;
            text-decoration: none;
            img {
              width: 1rem;
              height: 1rem;
              margin-right: 10px;
            }
          }
        }
      }

      .nav-right {
        .nav-item {
          display: none;
        }
      }
    }
  }

  .member_register-form {
    .group-button {
      justify-content: center;
    }
    .member_register-tabs {
      .registration-content .button-slide .btn-primary {
        margin: 0px 10px;
      }
    }
    .modal-document .alert .wrap-custom-file {
      img {
        width: 100px !important;
      }
    }
  }
  .member_register-form .form-main {
    padding: 15px;
  }
  .content-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    white-space: normal;
    .member_register-management {
      flex-direction: column;
      min-width: 320px;
      .tab-content {
        min-width: 375px;
        height: 300px;
      }
    }
  }

  .member_register-form {
    min-width: 320px;
    margin-left: 0px;
    border: 1px solid #939598;
    .group-btn-mb {
      flex-wrap: wrap;
    }
    .center-tabs {
      margin-left: 10px;
      margin-right: 10px;
    }
    .modal-postCode {
      .alert {
        .address-input {
          span {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 424px) {
  .member_register-form {
    .member_register-tabs {
      margin: 10px;
      .table-wrapper {
        margin-left: 0;
        margin-right: 0;
      }
      .tabs {
        .tab-link {
          font-size: 11px;
          padding: 9.5px;
        }
      }
      .group-bottom {
        flex-direction: column;
      }
      .pagination {
        margin-top: 20px;
      }
    }
  }
  .member_register-tabs {
    .registration-content {
      .form {
        .group-form {
          flex-direction: column;
          .form-mobile,
          .form-phone,
          .form-bank,
          .form-accountNum,
          .form-despositor,
          .form-address,
          .address-input,
          .form-street {
            width: 100%;
          }
          .form-accountNum {
            margin-top: 25px;
          }
        }
        .form-addressMB .form-street {
          margin-top: 25px;
        }
        .form-regisDocs .doc-notice {
          margin: 0;
        }
      }
    }
  }

  .form .form-regisDocs .file-up-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    .file-uploaded {
      margin-top: 10px;
      &:nth-child(2) {
        margin-left: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .member_register-content {
    .member_register-form {
      .group-row {
        // flex-direction: column;
        .form-check:nth-child(2) {
          margin-left: 0;
        }
      }
      .member_register-tabs {
        .registration-content {
          .form-wrapper {
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: center;
            .form-top {
              width: 100%;
              margin-top: 15px;
            }
          }
        }
      }
    }
    .member_register-management {
      .nav-main .nav-item.active:after {
        right: 0;
      }
    }
  }
}
