.center-registration-form {
  .basic-information-group {
    .dx-form-group-content {
      padding-bottom: 0;
    }
    .basic-information-group-item {
      margin-bottom: 80px;
    }
  }

  .center-info-label {
    .dx-field-item-label-location-top {
      width: 200px;
    }
  }

  .center-info-select {
    .dx-field-item-content-location-bottom {
      width: 80px;
    }
  }
}
