.popup-product-image-content {
  height: 80vh;
  padding: 8;
  background-color: #fff;
  border-radius: 6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);

  .content-left {
    width: 60%;
    height: 100%;
    padding: 10px;
  }
  .content-right {
    width: 40%;
    height: 100%;
    border-left: 1px solid rgba(52, 64, 84, 0.3);
  }

  .dx-drag-list {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
    padding: 10px;
    width: 100%;
    .dx-draggable-content {
      border-radius: 5px;
      aspect-ratio: 1 / 1;
      .drag-image {
        border-radius: 5px;
        padding: 5px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px;
        .image-view {
          border-radius: 5px;
        }
      }
      .custome-view-image {
        position: relative;
        border-radius: 10px;
        padding: 5px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px;
        .image-view {
          border-radius: 5px;
          width: 100%;
          height: 100%;
        }
      }
      .preview-trash-image {
        display: none;
        position: absolute;
        top: -15px;
        right: 0px;
        i {
          font-size: 20px;
          padding-top: 10px;
          color: #7ab8eb;
        }
        .fa-trash {
          color: #d9534fd4 !important;
          cursor: 'pointer';
          font-size: 16px;
          margin-left: 10px;
        }
      }
      &:hover {
        .preview-trash-image {
          display: block;
        }
      }
    }
  }

  .dx-drop-list {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    width: fit-content !important;
    grid-gap: 20px;
    padding: 10px;
    background-color: #f5f5f5b0;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    .dx-drop-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 5px;
      flex-wrap: wrap;
      .custome-view-image {
        position: relative;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px;
        width: 100px;
        height: 100px;
        background-color: #fff;
        .image-view {
          border-radius: 5px;
          width: 100px;
          height: 100px;
          object-fit: contain;
        }
      }
      .preview-trash-image {
        display: none;
        position: absolute;
        top: -20px;
        right: 0px;
        i {
          font-size: 18px;
          padding-top: 10px;
          color: #7ab8eb;
        }
        .fa-trash {
          color: #d9534fd4 !important;
          cursor: 'pointer';
          font-size: 16px;
          margin-left: 10px;
        }
      }
      &:hover {
        .preview-trash-image {
          display: block;
        }
      }
    }
  }

  .product-drop-list {
    flex-flow: row wrap;
    grid-template-columns: auto !important;
    width: fit-content !important;
    .dx-drop-content {
      flex-wrap: wrap;
      width: 130px;
      .custome-view-image {
        width: 130px;
        height: 130px;
        .image-view {
          width: 130px;
          height: 130px;
        }
      }
    }
  }
}

@media screen and (max-width: 1380px) {
  .dx-drop-list {
    .dx-drop-content {
      .custome-view-image {
        width: 70px !important;
        height: 70px !important;
        .image-view {
          width: 70px !important;
          height: 70px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .dx-drop-list {
    .dx-drop-content {
      .custome-view-image {
        width: 55px !important;
        height: 55px !important;
        .image-view {
          width: 55px !important;
          height: 55px !important;
        }
      }
    }
  }
}
