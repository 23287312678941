.my-page-change-email-popup {
  .dx-popup-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .dx-field-item-help-text {
      color: rgba(217,83,79, 1);
    }

    .my-page-btn-footer {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
    }
  }
}
