.header {
  .header-wrapper {
    .menu-dashboard {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 0 12px;
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}
